import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthorityService } from './authority.service';

export const AU_USER = 'usernameFC';
export const AU_PASS = 'passwordFC';
export const AU_PASS_C = 'passwordConfirmFC';
export const AU_CAPTCHA = 'recaptchaFC';

@Component({
  selector: 'app-authority',
  templateUrl: './authority.component.html',
  styleUrls: ['./authority.component.css']
})

export class AuthorityComponent implements OnInit {
  @Input() modelFunction : String;
  @Input() addAuthorityForm : FormGroup;
  @Input() signinForm : FormGroup;
  @Input() showRecaptcha : boolean;
  @Output() recaptchaClicked = new EventEmitter<String>();
  usernameFC : string = AU_USER;
  passwordFC : string = AU_PASS;
  passwordConfirmFC : string = AU_PASS_C;
  recaptchaFC : string = AU_CAPTCHA;
  hasSession : boolean = false;
  displayName : string;

  constructor(
    private authService : AuthorityService,
    private changeDetection : ChangeDetectorRef,
    private ngZone : NgZone
  ) {
    this.handleAuthority = this.handleAuthority.bind(this);
  }

  handleAuthority(response) {
    if (response && response.as) {
      this.hasSession = true;
      if (response) this.displayName = response.display;
      this.ngZone.run(() => { this.changeDetection.detectChanges() });
    }
  }

  ngOnInit() {
    if (this.modelFunction === 'WIDGET' || this.modelFunction === 'PANEL') {
      this.authService.getAuthority(this.handleAuthority);
    }
  }
}
