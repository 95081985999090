
export const CU_FIRST_NAME = 'firstNameFC';
export const CU_LAST_NAME = 'lastNameFC';
export const CU_PHONE = 'phoneFC';
export const CU_EMAIL = 'emailFC';
export const CU_STREET_1 = 'street1FC';
export const CU_STREET_2 = 'street2FC';
export const CU_CITY = 'cityFC';
export const CU_STATE = 'stateFC';
export const CU_ZIPCODE = 'zipcodeFC';
