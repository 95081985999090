import { Component, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CatalogItemService } from '../../models/catalog-item/catalog-item.service';
import {
  LAPTOP, SMARTPHONE, SMARTWATCH, TABLET, DESKTOP
} from '../../models/catalog-category/catalog-category.component';

@Component({
  selector: 'app-catalog-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['../page.css', './catalog-page.component.css', './search-page.component.css']
})
export class SearchPageComponent implements OnInit {
  catalogItems : Array<Object>;
  title : string = 'Catalog Search';
  subtitle : string = 'Buy Back';
  searchForm : FormGroup;
  loading : boolean = false;

  constructor(
    route: ActivatedRoute,
    private catalogItemService : CatalogItemService,
    private changeDetection : ChangeDetectorRef,
    private ngZone : NgZone
  ) {
    this.handleItems = this.handleItems.bind(this);
  }

  ngOnInit() {
    this.searchForm =  new FormGroup({
      searchTerms: new FormControl('')
    });
  }

  searchItems() {
    const terms = this.searchForm.controls.searchTerms.value;
    if (terms && terms.length) {
      this.loading = true;
      this.catalogItemService.searchItems(this.handleItems, terms);
    }
  }

  handleItems(response) {
    this.catalogItems = response;
    this.loading = false;
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }

  // displayCategory(categoryIdentifier) {
  //   switch (Number.parseInt(this.categoryIdentifier)) {
  //     case LAPTOP:
  //       return 'Laptops';
  //
  //     case SMARTPHONE:
  //       return 'Smartphones';
  //
  //     case SMARTWATCH:
  //       return 'Smartwatches';
  //
  //     case TABLET:
  //       return 'Tablets';
  //
  //     case DESKTOP:
  //       return 'Deskop Computers';
  //
  //     default:
  //       return 'Featured';
  //   }
  // }
}
