import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorityFunctionService } from './authority-function.service';
import { AuthorityFunctionGuard } from './authority-function.guard';

@Injectable()
export class ManageCatalogGuard extends AuthorityFunctionGuard {
  constructor(private aService : AuthorityFunctionService, private aRouter: Router, private aNgZone: NgZone) {
    super('manage_catalog', aService, aRouter, aNgZone);
  }
}
