import { Injectable } from '@angular/core';
import rp from 'request-promise';
import { environment } from '../../../environments/environment';
import { HttpClient } from "@angular/common/http";

const serverAdx = environment.apiHost;

const baseRoute = '/api/catalog/item';

@Injectable()
export class PricePointService {

  constructor(private httpClient: HttpClient) {}

  getPricePoints(callback, catalogItemId) {
    rp(`${serverAdx}${baseRoute}/${catalogItemId}/pricepoint`).promise().bind(this)
      .then(function (responseBody) {
          const pricePoints = JSON.parse(responseBody);
          callback(pricePoints['pricePoints']);
      }).catch(() => {});
  }

  getDetailedPricePoints(catalogItemId, carrier, condition) {
    let params = `condition_key=${condition}`;
    if (carrier) params += `&carrier_key=${carrier}`;
    return this.httpClient.get(
       `${serverAdx}${baseRoute}/${catalogItemId}/pricepoint/detail?${params}`
    );
  }

}
