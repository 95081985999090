import { Component } from '@angular/core';

@Component({
  selector: 'ui-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.css']
})

export class MainFooterComponent {
  aYear = (new Date()).getFullYear();
}
