import { Injectable } from '@angular/core';
import rp from 'request-promise';
import { environment } from '../../../environments/environment';

const serverAdx = environment.apiHost;

@Injectable()
export class CatalogBrandService {
  getBrands(callback, categoryIdentifier) {
    return rp(`${serverAdx}/api/catalog/category/${categoryIdentifier}/brand`).promise().bind(this)
      .then(responseBody => callback(JSON.parse(responseBody)))
      .catch(() => callback(false));
  }

  updatePricePoints(callback, brandId, categoryIdentifier, actionKey, actionValue) {
    const options = {
      method: 'PUT',
      uri: `${serverAdx}/api/catalog/category/${categoryIdentifier}/brand/${brandId}/pricepoint`,
      body: { actionKey, actionValue },
      json: true,
      withCredentials: true
    };
    return rp(options).promise().bind(this)
      .then(function(responseBody) {
        return callback(responseBody.result);
      })
      .catch(function () {
        // const responseBody = errorObject.response.body;
        return callback(false);
      });
  }
}
