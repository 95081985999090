import { Component, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthorityService } from '../../models/authority/authority.service';
import { AU_USER, AU_PASS, AU_CAPTCHA } from '../../models/authority/authority.component';

const infoMessages = {
  signed_up: 'Thank you for registering!  Please sign in to your account.'
};

@Component({
  selector: 'app-signin-page',
  templateUrl: './signin-page.component.html',
  styleUrls: ['../page.css', './signin-page.component.css']
})

export class SigninPageComponent implements OnInit {
  subtitle : string = 'Sign In';
  infoMessage : string;
  signinForm : FormGroup;
  showRecaptcha : boolean;
  isEmployee : boolean = false;
  formSubmitted : boolean = false;
  isOrderFlow = false;
  isShip = false;
  isExpedite = false;
  isInsure = false;
  payMethod : string;
  payContact : string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService : AuthorityService,
    private snackBar: MatSnackBar,
    private changeDetection : ChangeDetectorRef,
    private ngZone: NgZone
  ) {
    this.handleSignIn = this.handleSignIn.bind(this);
  }

  ngOnInit() {
    this.signinForm =  new FormGroup({
      [AU_USER]: new FormControl('', Validators.required),
      [AU_PASS]: new FormControl('', Validators.required)
    });
    const flowParam = this.route.snapshot.paramMap.get('flow');
    if ('order' === flowParam) {
      this.isOrderFlow = true;
    }
    this.payMethod = this.route.snapshot.paramMap.get('pay');
    this.payContact = this.route.snapshot.paramMap.get('pay_contact') || '';
    this.isShip = this.route.snapshot.paramMap.get('ship') === 'true';
    this.isExpedite = this.route.snapshot.paramMap.get('expedite') === 'true';
    this.isInsure = this.route.snapshot.paramMap.get('insure') === 'true';
    if (this.route.snapshot.paramMap.has('message_key')) {
      this.infoMessage = infoMessages[this.route.snapshot.paramMap.get('message_key')];
      if (this.infoMessage) {
        setTimeout(() => {
          this.snackBar.open(this.infoMessage, 'Dismiss', { duration: 10000 });
        });
      }
    }
  }

  handleSignIn(isSignedIn, isMaxRetriesExceeded) {
    if (isSignedIn) {
      let destination = '/home';
      const params = {};
      if (this.isOrderFlow) {
        destination = '/order_summary';
        params['pay'] = this.payMethod;
        params['pay_contact'] = this.payContact;
        params['ship'] = this.isShip;
        params['expedite'] = this.isExpedite;
        params['insure'] = this.isInsure;
      }
      else if (this.route.snapshot.paramMap.has('dest')) destination = this.route.snapshot.paramMap.get('dest');
      this.ngZone.run(() => this.router.navigate([destination, params]));
    } else {
      this.formSubmitted = false;
      this.showRecaptcha = isMaxRetriesExceeded === true;
      if (this.showRecaptcha) {
        this.signinForm.addControl(AU_CAPTCHA, new FormControl(null, Validators.required));
      }
      this.snackBar.open('Signin failed, please try again.', 'Dismiss', { duration: 10000 });
      this.ngZone.run(() => { this.changeDetection.detectChanges() });
    }
  }

  goToSignUp(event) {
    event.preventDefault();
    this.router.navigate(
      [
        '/signup',
        {...(
          this.isOrderFlow
          && {
            flow: 'order',
            pay: this.payMethod,
            pay_contact: this.payContact,
            ship: this.isShip,
            expedite: this.isExpedite,
            insure: this.isInsure
          }
        )}
      ]
    );
  }

  submitForm() {
    let captchaValue = null;
    this.signinForm.controls[AU_USER].markAsTouched();
    this.signinForm.controls[AU_PASS].markAsTouched();
    if (this.showRecaptcha) {
      this.signinForm.controls[AU_CAPTCHA].markAsTouched();
      captchaValue = this.signinForm.controls[AU_CAPTCHA].value
    }
    if (this.signinForm.valid) {
      this.formSubmitted = true;
      this.authService.signInUser(
        this.signinForm.controls[AU_USER].value,
        this.signinForm.controls[AU_PASS].value,
        captchaValue,
        this.isEmployee,
        this.handleSignIn
      );
      this.signinForm.removeControl(AU_CAPTCHA);
      this.showRecaptcha = false;
      this.signinForm.reset();
    }
  }
}
