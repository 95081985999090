import { Component, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { CatalogItemService } from '../../../models/catalog-item/catalog-item.service';
import { CatalogBrandComponent } from '../../../models/catalog-brand/catalog-brand.component';
import { CatalogBrandService } from '../../../models/catalog-brand/catalog-brand.service';
import { SMARTPHONE, TABLET, LAPTOP, DESKTOP } from '../../../models/catalog-category/catalog-category.component';

@Component({
  selector: 'app-manage-catalog-page',
  templateUrl: './manage-catalog-page.component.html',
  styleUrls: ['../../page.css', './manage-catalog-page.component.css']
})

export class ManageCatalogPageComponent {
  brandIdentifier : string;
  categoryIdentifier: string;
  brands : Array<CatalogBrandComponent>;
  categoryItems : Array<Object>;
  title : string = 'Manage Catalog';
  subtitle : string = 'Update prices by brand or browse';

  categories = {
    Smartphone: SMARTPHONE,
    Tablets: TABLET,
    Laptops: LAPTOP,
    Desktops: DESKTOP
  }

  constructor(
    private router: Router,
    private catalogItemService : CatalogItemService,
    private catalogBrandService : CatalogBrandService,
    private changeDetection : ChangeDetectorRef,
    private ngZone : NgZone
  ) {
    this.handleBrands = this.handleBrands.bind(this);
    this.handleItems = this.handleItems.bind(this);
  }

  categoryList() {
    return Object.keys(this.categories);
  }

  chooseCategory(aCategoryIdentifier) {
    this.categoryIdentifier = aCategoryIdentifier;
    this.catalogBrandService.getBrands(this.handleBrands, this.categoryIdentifier);
  }

  chooseBrand(aBrandIdentifier) {
    this.brandIdentifier = aBrandIdentifier;
    this.catalogItemService.getByBrand(this.handleItems, this.categoryIdentifier, this.brandIdentifier);
  }

  handleBrands(response) {
    this.brands = response.map(brand => new CatalogBrandComponent(brand['identifier'], brand['name']));
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }

  browseBrand(aBrandIdentifier) {
    this.ngZone.run(
      () => this.router.navigate(
        ['/eo/manage_catalog/prices', { type_key: 'brand', target_id: aBrandIdentifier }]
      )
    );
  }

  handleItems(response, brandName) {
    this.subtitle = `Update ${brandName} prices by item`;
    this.categoryItems = response;
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }

  clearBrand() {
    this.brandIdentifier = undefined;
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }

  clearCategory() {
    this.categoryIdentifier = undefined;
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }
}
