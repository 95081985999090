import { Injectable } from '@angular/core';
import rp from 'request-promise';
import { environment } from '../../../environments/environment';
import {
    CU_FIRST_NAME,
    CU_LAST_NAME,
    CU_PHONE,
    CU_EMAIL,
    CU_STREET_1,
    CU_STREET_2,
    CU_CITY,
    CU_STATE,
    CU_ZIPCODE
} from './customer.constants';
import { CustomerGuidService } from '../customer-order/customer-guid.service';

const serverAdx = environment.apiHost;

@Injectable()
export class CustomerService {
  constructor(private guidService : CustomerGuidService) {}

  addCustomer(customerFormGroup, username, password, callback) {
    const requestBody = {
      first_name: customerFormGroup[CU_FIRST_NAME],
      last_name: customerFormGroup[CU_LAST_NAME],
      phone: customerFormGroup[CU_PHONE],
      email: customerFormGroup[CU_EMAIL],
      street_1: customerFormGroup[CU_STREET_1],
      street_2: customerFormGroup[CU_STREET_2],
      city: customerFormGroup[CU_CITY],
      state: customerFormGroup[CU_STATE],
      zipcode: customerFormGroup[CU_ZIPCODE],
      ...(username && { user_name: username }),
      ...(password && { password }),
      cartIdentifier: this.guidService.getCartIdentifier()
    }
    const options = {
      method: 'POST',
      uri: `${serverAdx}/api/customer`,
      body: requestBody,
      json: true,
      withCredentials: true
    };
    rp(options).promise().bind(this)
      .then(function () {
        callback(true);
      }).catch(function(errorResponse) {
        callback(false, errorResponse && errorResponse.error ? errorResponse.error.errorKey : undefined);
      });
  }

  getCustomersWithOrders(callback) {
    rp(`${serverAdx}/api/customer/with_orders`).promise().bind(this)
      .then(function (responseBody) {
          callback(JSON.parse(responseBody).customers);
      }).catch(() => {});
  }

  getCustomer(customerIdentifier, callback) {
    rp(`${serverAdx}/api/customer/${customerIdentifier}`).promise().bind(this)
      .then(function (responseBody) {
          callback(JSON.parse(responseBody).customer);
      }).catch(() => {});
  }
}
