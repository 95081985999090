import { Component, NgZone, ChangeDetectorRef, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  PricePointLogic, CARRIERS, CONDITIONS, PROCESSORS, STORAGES, OPERATIONS, BATTERIES
} from '../../models/price-point/price-point.logic';
import { CatalogItemService } from '../../models/catalog-item/catalog-item.service';
import { CatalogBrandService } from '../../models/catalog-brand/catalog-brand.service';
import { PricePointService } from '../price-point/price-point.service';
import { SMARTPHONE, TABLET, LAPTOP, DESKTOP } from '../../models/catalog-category/catalog-category.component';
import { CustomerOrderLogic } from './customer-order.logic';

const DEFAULT_POINT_TYPES = ['storage', 'operation']; // no accessories
const LAPTOP_POINT_TYPES = ['processor', 'storage', 'operation', 'battery']; // no accessories
const DESKTOP_POINT_TYPES = ['processor', 'storage', 'operation'];

@Component({
  selector: 'customer-order-add-item-dialog',
  templateUrl: 'customer-order-add-item-dialog.html',
})
export class AddItemDialog implements OnInit {
  loading = false;
  pricePoints = undefined;
  detailedPricePoints = undefined;
  brands = [];
  itemName = '';
  presentQuantity = 1;
  presentPricePointId : number = undefined;
  finalPricePointId : number = undefined;
  presentCategoryIdentifier = SMARTPHONE;
  presentBrandIdentifier;
  presentItemIdentifier;
  allItems;
  CARRIER_NAMES = CARRIERS;
  CONDITION_NAMES =  CONDITIONS;
  CATEGORIES = [
    { name: "Smartphone", id: SMARTPHONE },
    { name: "Tablet", id: TABLET },
    { name: "Laptop", id: LAPTOP },
    { name: "Desktop", id: DESKTOP },
  ];
  detailedSelections = {};
  pointDisplayMap = {
    processor: PROCESSORS, storage: STORAGES, operation: OPERATIONS, battery: BATTERIES
  };

  constructor(
    public dialogRef: MatDialogRef<AddItemDialog>,
    private ngZone : NgZone,
    private changeDetection : ChangeDetectorRef,
    private catalogItemService : CatalogItemService,
    private catalogBrandService : CatalogBrandService,
    private pricePointService : PricePointService,
  ) {
    this.handleBrands = this.handleBrands.bind(this);
    this.handleCatalogItems = this.handleCatalogItems.bind(this);
    this.handlePricePoints = this.handlePricePoints.bind(this);
  }

  ngOnInit() {
    this.catalogBrandService.getBrands(this.handleBrands, this.presentCategoryIdentifier);
  }

  handleBrands(response) {
    this.brands = response;
    this.presentBrandIdentifier = this.brands[0].identifier
    this.loading = false;
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }

  brandChange(aBrandIdentifier) {
    this.presentBrandIdentifier = aBrandIdentifier.value;
  }

  categoryChange(categoryIdentifier) {
    this.presentCategoryIdentifier = categoryIdentifier.value;
    this.loading = true;
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
    this.catalogBrandService.getBrands(this.handleBrands, this.presentCategoryIdentifier);
  }

  loadItems() {
    this.loading = true;
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
    this.catalogItemService.getByBrand(this.handleCatalogItems, this.presentCategoryIdentifier, this.presentBrandIdentifier);
  }

  handleCatalogItems(response) {
    this.allItems = response.sort(CustomerOrderLogic.simpleComparator('name'));
    this.loading = false;
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }

  itemChange(itemIdentifier) {
    this.pricePoints = undefined;
    this.presentPricePointId = undefined;
    if (itemIdentifier.value == '-1') {
      this.presentItemIdentifier = undefined;
    } else {
      this.presentItemIdentifier = itemIdentifier.value;
      this.loading = true;
      this.pricePointService.getPricePoints(this.handlePricePoints, this.presentItemIdentifier);
    }
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }

  handlePricePoints(somePricePoints) {
    this.pricePoints = somePricePoints;
    this.presentPricePointId = this.pricePoints[0].identifier;
    this.loading = false;
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }

  presentPricePoint() {
    return this.pricePoints.find(pricePoint => pricePoint['identifier'] === this.presentPricePointId);
  }

  presentCarrier() {
    return this.presentPricePoint()['carrierKey'];
  }

  presentCondition() {
    return this.presentPricePoint()['conditionKey'];
  }

  carriers() {
    return PricePointLogic.carriers(this.pricePoints);
  }

  conditions() {
    return PricePointLogic.conditions(this.pricePoints, this.presentCarrier());
  }

  carrierChange(carrierKey) {
    this.presentPricePointId = PricePointLogic.pricePointForCarrier(this.pricePoints, carrierKey.value);
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }

  conditionChange(conditionKey) {
    this.presentPricePointId = PricePointLogic.pricePointForCarrierCondition(
      this.pricePoints,
      this.presentCarrier(),
      conditionKey.value
    );
  }

  loadDetailedPricePoints() {
    this.loading = true;
    this.pricePointService.getDetailedPricePoints(
      this.presentItemIdentifier, this.presentCarrier(), this.presentCondition()
    ).subscribe((response: any) => {
      this.detailedPricePoints = response.pricePoints;
      this.detailedSelections = { carrier: this.presentCarrier(), condition: this.presentCondition() };
      this.loading = false;
    });
  }

  updateUI() {
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }

  quantityChange(quantity) {
    this.presentQuantity = parseInt(quantity);
  }

  detailedPoint(pointKey) {
    return PricePointLogic.detailedPoint(this.detailedPricePoints, this.detailedSelections, pointKey);
  }

  baseTypes() {
    let baseType = DEFAULT_POINT_TYPES;
    if (this.presentCategoryIdentifier === LAPTOP) baseType = LAPTOP_POINT_TYPES;
    else if (this.presentCategoryIdentifier === DESKTOP) baseType = DESKTOP_POINT_TYPES;
    return baseType;
  }

  detailedPosition(baseType) {
    const selectedTypes = Object.keys(this.detailedSelections);
    return Math.max(...selectedTypes.map(sKey => baseType.indexOf(sKey)));
  }

  detailedPointTypes() {
    const baseType = this.baseTypes();
    const currentTypes = baseType.slice(0, this.detailedPosition(baseType) + 2);
    return currentTypes.filter(type => PricePointLogic.hasDetailedPoint(this.detailedPricePoints, type));
  }

  pointChange(pointKey, detailedPointType) {
    this.detailedSelections[detailedPointType] = pointKey.value;
  }

  closeDialog(data = undefined) {
    this.dialogRef.close(data);
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }

  saveDetailedPricePoints() {
    const possbileTypes = this.baseTypes().filter(
      type => PricePointLogic.hasDetailedPoint(this.detailedPricePoints, type)
    );
    if (possbileTypes.length === (this.detailedPosition(this.baseTypes()) + 1)) {
      this.finalPricePointId = PricePointLogic.selectedPricePoint(
        this.detailedPricePoints, this.detailedSelections
      )['identifier'];
    }
  }

  addItem() {
    if (this.presentQuantity) {
      const catalogItem = this.allItems.find(catalogItem => this.presentItemIdentifier == catalogItem.identifier);
      const pricePointItem = CustomerOrderLogic.customerOrderItem(
        this.finalPricePointId,
        catalogItem.identifier,
        catalogItem.imageFilename,
        catalogItem.name,
        this.presentQuantity,
        this.brands.find(brand => this.presentBrandIdentifier == brand.identifier)['name'],
        this.detailedSelections['processor'],
        this.detailedSelections['storage'],
        this.detailedSelections['operation'],
        this.detailedSelections['battery'],
        undefined, // accessories
        this.presentCarrier(),
        this.presentCondition(),
        this.presentPricePoint()['price']
      );
      this.closeDialog(pricePointItem);
    }
  }

}
