
export const CARRIERS = {
  att: 'AT&T',
  sprint: 'Sprint',
  tmobile: 'T-Mobile',
  verizon: 'Verizon',
  unlocked: 'Unlocked',
  metropcs: 'MetroPCS',
  other: 'Other Carrier',
};

export const CONDITIONS = {
  broken: 'Broken',
  fair: 'Fair',
  good: 'Good',
  flawless: 'Flawless',
  new: 'Brand New'
};

export const PROCESSORS = {
  '1_1_m':'1.1 GHz Intel Core m',
  '1_2_m':'1.2 GHz Intel Core m',
  '1_3_m':'1.3 GHz Intel Core m',
  '1_1_m3':'1.1 GHz Intel Core m3',
  '1_2_m2':'1.2 GHz Intel Core m3',
  '1_2_m5':'1.2 GHz Intel Core m5',
  '1_3_m7':'1.3 GHz Intel Core m7',
  '1_1_i3':'1.1 GHz Intel Core i3',
  '3_6_i3':'3.6 GHz Intel Core i3',
  '1_1_i5':'1.1 GHz Intel Core i5',
  '1_3_i5':'1.3 GHz Intel Core i5',
  '1_4_i5':'1.4 GHz Intel Core i5',
  '1_6_i5':'1.6 GHz Intel Core i5',
  '1_7_i5':'1.7 GHz Intel Core i5',
  '1_8_i5':'1.8 GHz Intel Core i5',
  '2_0_i5':'2.0 GHz Intel Core i5',
  '2_3_i5':'2.3 GHz Intel Core i5',
  '2_4_i5':'2.4 GHz Intel Core i5',
  '2_5_i5':'2.5 GHz Intel Core i5',
  '2_6_i5':'2.6 GHz Intel Core i5',
  '2_7_i5':'2.7 GHz Intel Core i5',
  '2_8_i5':'2.8 GHz Intel Core i5',
  '2_9_i5':'2.9 GHz Intel Core i5',
  '3_0_i5':'3.0 GHz Intel Core i5',
  '3_1_i5':'3.1 GHz Intel Core i5',
  '3_3_i5':'3.3 GHz Intel Core i5',
  '3_4_i5':'3.4 GHz Intel Core i5',
  '3_5_i5':'3.5 GHz Intel Core i5',
  '3_7_i5':'3.7 GHz Intel Core i5',
  '3_8_i5':'3.8 GHz Intel Core i5',
  '1_2_i7':'1.2 GHz Intel Core i7',
  '1_4_i7':'1.4 GHz Intel Core i7',
  '1_7_i7':'1.7 GHz Intel Core i7',
  '2_0_i7':'2.0 GHz Intel Core i7',
  '2_2_i7':'2.2 GHz Intel Core i7',
  '2_3_i7':'2.3 GHz Intel Core i7',
  '2_4_i7':'2.4 GHz Intel Core i7',
  '2_5_i7':'2.5 GHz Intel Core i7',
  '2_6_i7':'2.6 GHz Intel Core i7',
  '2_7_i7':'2.7 GHz Intel Core i7',
  '2_8_i7':'2.8 GHz Intel Core i7',
  '2_9_i7':'2.9 GHz Intel Core i7',
  '3_0_i7':'3.0 GHz Intel Core i7',
  '3_1_i7':'3.1 GHz Intel Core i7',
  '3_2_i7':'3.2 GHz Intel Core i7',
  '3_3_i7':'3.3 GHz Intel Core i7',
  '3_5_i7':'3.5 GHz Intel Core i7',
  '3_6_i7':'3.6 GHz Intel Core i7',
  '4_2_i7':'4.2 GHz Intel Core i7',
  '2_9_i9':'2.9 GHz Intel Core i9',
  '3_6_i9':'3.6 GHz Intel Core i9',
  '3_1_6core_i5':'3.1 GHz 6-core Intel Core i5',
  '3_3_6core_i5':'3.3 GHz 6-core Intel Core i5',
  '2_6_6core_i7':'2.6 GHz 6-core Intel Core i7',
  '3_8_8core_i7':'3.8 GHz 8-core Intel Core i7',
  '2_3_8core_i9':'2.3 GHz 8-core Intel Core i9',
  '2_4_8core_i9':'2.4 GHz 8-core Intel Core i9',
  '3_6_10core_i9':'3.6 GHz 10-core Intel Core i9',
  'm1':'M1 Chip',
  'm1_7gpu':'Apple M1 7-Core GPU',
  'm1_8gpu':'Apple M1 8-Core GPU',
  'm1_8core_7gpu':'M1 Chip 8-Core, 7-Core GPU',
  'm1_8core_8gpu':'M1 Chip 8-Core, 8-Core GPU',
  'm1pro_10core_16gpu': 'M1 Pro 10-Core, 16-Core GPU',
  'm1max_10core_24gpu':'M1 Max 10-Core, 24-core GPU',
  'm1max_10core_32gpu':'M1 Max 10-Core, 32-Core GPU',
  'm1ultra_20core_48gpu':'M1 Ultra 20-Core, 48-Core GPU',
  'm1ultra_20core_64gpu':'M1 Ultra 20-Core, 64-Core GPU',
  'm2_8core_8gpu':'M2 8-Core, 8-Core GPU',
  'm2_8core_10gpu':'M2 8-Core, 10-Core GPU',
  'm2pro_10core_16gpu':'M2 Pro 10-Core, 16-Core GPU',
  'm2pro_12core_19gpu':'M2 Pro 12-Core, 19-Core GPU',
  'm2max_12core_30gpu':'M2 Max 12-Core, 30-core GPU',
  'm2max_12core_38gpu':'M2 Max 12-Core, 38-Core GPU',
  'm2ultra_24core_60gpu':'M2 Ultra 24-Core, 60-Core GPU',
  'm2ultra_24core_76gpu':'M2 Ultra 24-Core, 76-Core GPU',
  '3_2_8core_w':'3.2GHz 8-Core Intel Xeon W',
  '3_5_8core_w':'3.5GHz 8-Core Intel Xeon W',
  '3_0_10core_w':'3.0GHz 10-Core Intel Xeon W',
  '3_3_12core_w':'3.3GHz 12-Core Intel Xeon W',
  '2_5_14core_w':'2.5GHz 14-Core Intel Xeon W',
  '3_2_16core_w':'3.2GHz 16-Core Intel Xeon W',
  '2_3_18core_w':'2.3GHz 18-Core Intel Xeon W',
  '2_7_24core_w':'2.7GHz 24-Core Intel Xeon W',
  '2_5_28core_w':'2.5GHz 28-Core Intel Xeon W',
  '3_7_4core_e5':'3.7GHz Quad-Core Intel Xeon E5',
  '3_5_6core_e5':'3.5GHz 6-Core Intel Xeon E5',
  '3_0_8core_e5':'3.0GHz 8-Core Intel Xeon E5',
  '2_7_12core_e5':'2.7GHz 12-Core Intel Xeon E5'
};
export const STORAGES = {
  '32gb': '32GB',
  '64gb': '64GB',
  '128gb': '128GB',
  '256gb': '256GB',
  '512gb': '512GB',
  '1tb': '1TB',
  '2tb': '2TB',
  '64gbssd':'64GB SSD',
  '128gbssd':'128GB SSD',
  '256gbssd':'256GB SSD',
  '512gbssd':'512GB SSD',
  '768gbssd':'768GB SSD',
  '1tbssd': '1TB SSD',
  '1_5tbssd': '1.5TB SSD',
  '2tbssd': '2TB SSD',
  '4tbssd': '4TB SSD',
  '8tbssd': '8TB SSD',
  '500gbhdd':'500GB HDD',
  '1tbhdd':'1TB HDD',
  '2tbhdd':'2TB HDD',
  '3tbhdd':'3TB HDD',
  '1tbfusion':'1TB Fusion Drive',
  '2tbfusion':'2TB Fusion Drive',
  '3tbfusion':'3TB Fusion Drive'
};
export const OPERATIONS = {
  'yes':'Yes, works 100% as intended',
  'no':'No, does not work 100% as intended or does not turn on'
};
export const BATTERIES = {
  'good': 'Good, "Normal" condition',
  'poor': 'Poor, "Replace Now" or "Replace Soon" or "Service Battery" or "Check Battery" warning'
};
export const ACCESSORIES = {
  'box': 'Original Box',
  'power-cable-adapter': 'Powercable + Adapter',
  'headset': 'Original Headsets',
  'dual-screen': 'Dual Screen', // G8X ThinQ
  'laptop-charger':
    'OEM Charger, fully functional and in good condition without fraying, yellowing, wear or exposed wiring',
  'keyboard': 'Original Keyboard',
  'mouse-trackpad': 'Original Mouse or Trackpad',
  'adapter-cord': 'OEM Power Adapter and Cord, Must be OEM, functional and in good condition',
  'desktop-box': 'Original Box and Packaging, Highly recommend to prevent damage during shipping'
};

const typeMap = {
  carrier: Object.keys(CARRIERS),
  condition: Object.keys(CONDITIONS),
  processor: Object.keys(PROCESSORS),
  storage: Object.keys(STORAGES),
  operation: Object.keys(OPERATIONS),
  battery: Object.keys(BATTERIES),
  accessories: Object.keys(ACCESSORIES)
};

export class PricePointLogic {

  private static sortByType = type =>
    (a, b) => typeMap[type].indexOf(a) - typeMap[type].indexOf(b);

  private static matchDetailed = (point, selectionKey, selections) => {
    if (selections[selectionKey] === undefined) return true;
    if ('accessories' === selectionKey) {
      return (selections[selectionKey].length > 0) === (point[selectionKey] === 'Y');
    }
    return point[selectionKey] === selections[selectionKey];
  }

  private static filterDetailed = (point, selections) =>
    Object.keys(point).reduce(
      (result, selectionKey) => result && PricePointLogic.matchDetailed(point, selectionKey, selections),
      true
    );

  private static getSelectionValues = (detailedPricePoints, selectionKey) =>
    detailedPricePoints.map(point => point[selectionKey]).filter(x => !!x);

  static carriers(pricePoints) {
    const allCarriers = [];
    if (pricePoints) {
      pricePoints.forEach((pricePoint) => {
        const carrier = pricePoint['carrierKey'];
        if (carrier && allCarriers.indexOf(carrier) < 0) allCarriers.push(carrier);
      });
    }
    return allCarriers.sort(PricePointLogic.sortByType('carrier'));
  }

  static conditions(pricePoints, selectedCarrier) {
    let matches = pricePoints;
    if (selectedCarrier) {
      matches = pricePoints.filter(pricePoint => selectedCarrier == pricePoint['carrierKey'])
    }
    return matches.map(pricePoint => pricePoint['conditionKey']);
  }

  static hasDetailedPoint = (detailedPricePoints, selectionKey) =>
    PricePointLogic.getSelectionValues(detailedPricePoints, selectionKey).filter(x => x !== 'N').length > 0;

  static detailedPoint = (detailedPricePoints, selections, selectionKey) =>
    Array.from(new Set(PricePointLogic.getSelectionValues(
      detailedPricePoints.filter(point => PricePointLogic.filterDetailed(point, selections)), selectionKey
    ))).sort(PricePointLogic.sortByType(selectionKey));

  static selectedPricePoint = (detailedPricePoints, selections) =>
    detailedPricePoints.find(point => {
      for (const selectionKey of Object.keys(point)) {
        if (!PricePointLogic.matchDetailed(point, selectionKey, selections)) return false;
      }
      return true;
    });


  static pricePointForCarrier(pricePoints, carrierKey) {
    return pricePoints.filter(
      pricePoint => pricePoint['carrierKey'] === carrierKey
    ).sort(PricePointLogic.sortByType('condition'))[0]['identifier'];
  }

  static pricePointForCarrierCondition(pricePoints, carrierKey, conditionKey) {
    return pricePoints.find(
      pricePoint => pricePoint['carrierKey'] === carrierKey && pricePoint['conditionKey'] === conditionKey
    )['identifier'];
  }

}
