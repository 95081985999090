import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.css']
})

export class MainHeaderComponent {
  @Input() title = 'Buy Back';
  @Input() subtitle = 'Get a Quote, Get Paid';
}
