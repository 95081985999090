import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'customer-order-note-dialog',
  templateUrl: 'customer-order-note-dialog.html',
  styleUrls: ['./customer-order-dialog_shared.component.css']
})

export class OrderNoteDialog {
  note = '';

  constructor(
    public dialogRef: MatDialogRef<OrderNoteDialog>,
    @Inject(MAT_DIALOG_DATA) public noteData
  ) {
    this.note = noteData;
  }

  saveNote() {
    this.dialogRef.close(this.note);
  }

  noteChange(newValue) {
    this.note = newValue;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
