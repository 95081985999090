import { Component, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomerOrderService } from '../../../models/customer-order/customer-order.service';
import { CustomerService } from '../../../models/customer/customer.service';

@Component({
  selector: 'app-employee-summary-page',
  templateUrl: './summary-page.component.html',
  styleUrls: ['../../page.css', './summary-page.component.css']
})

export class EmployeeSummaryPageComponent implements OnInit {
  title : string = 'Manage Orders';
  subtitle : string = 'Customer Orders';
  customers : Object[];
  infoMessage : string;

  constructor(
    private customerOrderService : CustomerOrderService,
    private customerService : CustomerService,
    private changeDetection : ChangeDetectorRef,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private ngZone : NgZone,
    private router : Router
  ) {
    const paramMap = this.route.snapshot.paramMap;
    if (paramMap.has('key') && paramMap.get('key') === 'price_update') {
      if (paramMap.get('success') === 'true') {
        this.infoMessage = `Successfully updated ${paramMap.get('num_updated')} prices.`;
      } else if (paramMap.has('cancel')) {
        this.infoMessage = 'Price update canceled, no prices were updated.';
      } else {
        this.infoMessage = `Sorry, a failure occured, no prices could not be updated.`;
      }
    } else if (paramMap.has('key') && paramMap.get('key') === 'order_update') {
      if (paramMap.get('success') === 'false') {
        this.infoMessage = 'Failed to update order.';
      }
    }
    this.handleCustomers = this.handleCustomers.bind(this);
    this.handleCustomerOrders = this.handleCustomerOrders.bind(this);
  }

  loadOrders(customerIndex) {
    this.customerOrderService.getCustomersOrders(
      this.customers[customerIndex]['identifier'],
      this.handleCustomerOrders
    );
  }

  handleCustomers(customersData) {
    this.customers = customersData;
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }

  handleCustomerOrders(customerId, orders) {
    const orderInfo = Object.keys(orders).map(
      orderNumber => ({
        orderNumber: orderNumber,
        statusKey: orders[orderNumber].status_key,
        summaryTitle: orders[orderNumber].item_descriptions.join(', ').substring(0, 75),
        ship: orders[orderNumber].is_ship,
        expedite: orders[orderNumber].is_expedite,
        insure: orders[orderNumber].is_insure
      })
    ).sort((a, b) => { if (a.orderNumber == b.orderNumber) return 0; return a.orderNumber > b.orderNumber ? -1 : 1; });
    this.customers.find(customer => customerId === customer['identifier'])['orders'] = orderInfo;
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }

  ngOnInit() {
    if (this.infoMessage) {
      setTimeout(() => {
        this.snackBar.open(this.infoMessage, 'Dismiss', { duration: 10000 });
      });
    }
    this.customerService.getCustomersWithOrders(this.handleCustomers);
  }
}
