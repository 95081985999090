import { Component, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CatalogItemService } from '../../models/catalog-item/catalog-item.service';
import { CatalogBrandComponent } from '../../models/catalog-brand/catalog-brand.component';
import { CatalogBrandService } from '../../models/catalog-brand/catalog-brand.service';
import {
  LAPTOP, SMARTPHONE, SMARTWATCH, TABLET, DESKTOP
} from '../../models/catalog-category/catalog-category.component';

@Component({
  selector: 'app-catalog-page',
  templateUrl: './catalog-page.component.html',
  styleUrls: ['../page.css', './catalog-page.component.css']
})

export class CatalogPageComponent implements OnInit {
  categoryIdentifier : string;
  brandIdentifier : string;
  brands : Array<CatalogBrandComponent>;
  featuredItems = {}; // brand : string, item : CatalogItem
  categoryItems : Array<Object>;
  title : string;
  subtitle : string = 'Buy Back';

  constructor(
    route: ActivatedRoute,
    private catalogItemService : CatalogItemService,
    private catalogBrandService : CatalogBrandService,
    private changeDetection : ChangeDetectorRef,
    private ngZone : NgZone
  ) {
    this.categoryIdentifier = route.snapshot.paramMap.get('category');
    this.brandIdentifier = route.snapshot.paramMap.get('brand');
    this.handleFeatured = this.handleFeatured.bind(this);
    this.handleBrands = this.handleBrands.bind(this);
    this.handleItems = this.handleItems.bind(this);
    this.title = 'Browse All'
    switch (Number.parseInt(this.categoryIdentifier)) {
      case LAPTOP:
        this.title += ' Laptops';
        break;

      case SMARTPHONE:
        this.title += ' Smartphones';
        break;

      case SMARTWATCH:
        this.title += ' Smartwatches';
        break;

      case TABLET:
        this.title += ' Tablets';
        break;

      case DESKTOP:
        this.title += ' Deskop Computers';
        break;

      default:
    }
  }

  ngOnInit() {
    if (this.brandIdentifier) {
      this.catalogItemService.getByBrand(this.handleItems, this.categoryIdentifier, this.brandIdentifier);
    } else {
      this.catalogBrandService.getBrands(this.handleBrands, this.categoryIdentifier);
    }
  }

  chooseBrand(aBrandIdentifier) {
    this.brandIdentifier = aBrandIdentifier;
    this.catalogItemService.getByBrand(this.handleItems, this.categoryIdentifier, this.brandIdentifier);
  }

  handleBrands(response) {
    this.brands = response.map(brand => new CatalogBrandComponent(brand['identifier'], brand['name']));
    this.brands.forEach((brand) => {
      this.catalogItemService.getFeaturedItems(this.handleFeatured, this.categoryIdentifier, brand.identifier);
    });
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }

  handleFeatured(response, brandIdentifier) {
    if (response.length > 0) {
      this.featuredItems[brandIdentifier] =  response[0];
    }
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }

  handleItems(response, brandName) {
    this.title = `Browse ${brandName} Devices`;
    this.categoryItems = response;
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }
}
