import { Component, Input, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomerService } from './customer.service';
import {
    CU_FIRST_NAME,
    CU_LAST_NAME,
    CU_PHONE,
    CU_EMAIL,
    CU_STREET_1,
    CU_STREET_2,
    CU_CITY,
    CU_STATE,
    CU_ZIPCODE
} from './customer.constants';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})

export class CustomerComponent implements OnInit {
  @Input() customerIdentifier : number;
  @Input() modelFunction : String;
  @Input() addCustomerForm : FormGroup;
  firstNameFC : string = CU_FIRST_NAME;
  lastNameFC : string = CU_LAST_NAME;
  phoneFC : string = CU_PHONE;
  emailFC : string = CU_EMAIL;
  street1FC : string = CU_STREET_1;
  street2FC : string = CU_STREET_2;
  cityFC : string = CU_CITY;
  stateFC : string = CU_STATE;
  zipcodeFC : string = CU_ZIPCODE;
  stateCodes = [
    'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL',
    'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH',
    'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX',
    'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'
  ];
  firstName : string;
  lastName : string;
  phone : string = '-';
  email : string = '-';
  street1 : string;
  street2 : string;
  city : string;
  state : string;
  zipcode : string;
  customerIsLoaded = false;

  constructor(
    private customerService : CustomerService,
    private changeDetection : ChangeDetectorRef,
    private ngZone : NgZone
  ) {
    this.handleCustomerLoaded = this.handleCustomerLoaded.bind(this);
  }

  handleCustomerLoaded(data) {
    this.firstName = data.first_name;
    this.lastName = data.last_name;
    if (data.phone && data.phone.length) this.phone = data.phone;
    if (data.email && data.email.length) this.email = data.email;
    this.street1 = data.street_1;
    this.street2 = data.street_2;
    this.city = data.city;
    this.state = data.state;
    this.zipcode = data.zipcode;
    this.customerIsLoaded = true;
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }

  ngOnInit() {
    if(this.modelFunction == 'VIEW') {
      this.customerService.getCustomer(this.customerIdentifier, this.handleCustomerLoaded);
    }
  }
}
