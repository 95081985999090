import { Component, Inject, NgZone, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PricePointLogic, CARRIERS, CONDITIONS } from '../../models/price-point/price-point.logic';

@Component({
  selector: 'customer-order-edit-item-dialog',
  templateUrl: 'customer-order-edit-item-dialog.html',
  styleUrls: ['./customer-order-dialog_shared.component.css']
})

export class EditItemDialog {
  pricePoints = [];
  itemName = '';
  presentQuantity = 1;
  presentPricePointId : number = undefined;
  CARRIER_NAMES = CARRIERS;
  CONDITION_NAMES =  CONDITIONS;

  constructor(
    public dialogRef: MatDialogRef<EditItemDialog>,
    private ngZone : NgZone,
    private changeDetection : ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public editItemOptions
  ) {
    this.itemName = editItemOptions.itemName;
    this.pricePoints = editItemOptions.pricePoints;
    this.presentQuantity = editItemOptions.quantity;
    this.presentPricePointId = editItemOptions.selectedPricePoint;
  }

  closeDialog(data = undefined) {
    this.dialogRef.close(data);
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }

  presentPricePoint() {
    return this.pricePoints.find(pricePoint => pricePoint['identifier'] === this.presentPricePointId);
  }

  presentCarrier() {
    return this.presentPricePoint()['carrierKey'];
  }

  presentCondition() {
    return this.presentPricePoint()['conditionKey'];
  }

  carriers() {
    return PricePointLogic.carriers(this.pricePoints);
  }

  conditions() {
    return PricePointLogic.conditions(this.pricePoints, this.presentCarrier());
  }

  carrierChange(carrierKey) {
    this.presentPricePointId = PricePointLogic.pricePointForCarrier(this.pricePoints, carrierKey.value);
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }

  conditionChange(conditionKey) {
    this.presentPricePointId = PricePointLogic.pricePointForCarrierCondition(
      this.pricePoints,
      this.presentCarrier(),
      conditionKey.value
    );
  }

  updateUI() {
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }

  quantityChange(quantity) {
    this.presentQuantity = quantity;
  }

  updateItem() {
    this.closeDialog({ pricePoint: this.presentPricePointId, quantity: this.presentQuantity });
  }
}
