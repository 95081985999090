import { Injectable } from '@angular/core';
import rp from 'request-promise';
import { environment } from '../../environments/environment';

const serverAdx = environment.apiHost;

@Injectable()
export class AuthorityFunctionService {

  isAuthorized(functionKey) {
    return rp(`${serverAdx}/api/authority/function/${functionKey}`).promise().bind(this)
      .then(function (responseBody) {
          const data = JSON.parse(responseBody);
          return data['has_access'];
        });
  }

  getAuthorization(functionKey, callback) {
    rp(`${serverAdx}/api/authority/function/${functionKey}`).promise().bind(this)
      .then(function (responseBody) {
          const data = JSON.parse(responseBody);
          callback(data['has_access']);
        });
  }
}
