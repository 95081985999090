import { Component, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorityService } from '../../../models/authority/authority.service';

@Component({
  selector: 'employee-nav-menu-widget',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['../../page.css', './nav-menu.component.css']
})

export class NavMenuWidget implements OnInit {
  canManageCatalog = false;

  constructor(
    private router: Router,
    private authorityFunctionService : AuthorityService,
    private changeDetection : ChangeDetectorRef,
    private ngZone : NgZone
  ) {
    this.handleCatalogAuthority = this.handleCatalogAuthority.bind(this);
  }

  navTo(path) {
    this.ngZone.run(() => this.router.navigate([`/eo/${path}`]));
  }

  ngOnInit() {
    this.authorityFunctionService.getFunctions(this.handleCatalogAuthority);
  }

  handleCatalogAuthority(functions) {
    if (functions.includes('manage_catalog')) {
      this.canManageCatalog = true;
      this.ngZone.run(() => { this.changeDetection.detectChanges() });
    }
  }
}
