import { Component, OnInit, Input, ChangeDetectorRef, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  PricePointLogic, CARRIERS, CONDITIONS, PROCESSORS, STORAGES, OPERATIONS, BATTERIES, ACCESSORIES
} from '../../models/price-point/price-point.logic';
import { CatalogItemService } from '../../models/catalog-item/catalog-item.service';
import { PricePointService } from '../../models/price-point/price-point.service';
import {
  LAPTOP, SMARTPHONE, TABLET, DESKTOP
} from '../../models/catalog-category/catalog-category.component';

const QUOTE_STEPS = ['carrier', 'condition', 'processor', 'storage', 'operation', 'battery', 'accessories', 'quote'];

const STEP_DISPLAY_STRINGS = {
  carrier: 'Please choose your carrier',
  condition: 'What condition is your device in?',
  processor: 'What kind of processor does your device have?',
  storage: 'How much storage does your device have?',
  operation: 'Is your device fully functional?',
  battery: `Select you device's battery health`,
  accessories: 'What accessories will you include?',
  quote: 'Your quote'
};

const PHONE_STEPS = ['carrier', 'condition', 'storage', 'operation', 'accessories'];
const TABLET_STEPS = ['condition', 'storage', 'operation', 'accessories']; // no processor for apple
const LAPTOP_STEPS = ['condition', 'processor', 'storage', 'operation', 'battery', 'accessories'];
const DESKTOP_STEPS = ['condition', 'processor', 'storage', 'operation', 'accessories'];

class StateMachine {
  steps: Array<string>;

  constructor(someSteps) {
    this.steps = someSteps;
  }

  nextState(currentState, pricePoints, selections) {
    let nextIndex = this.steps.indexOf(currentState) + 1;
    let nextStep = this.steps[nextIndex];
    if (['carrier', 'condition'].includes(nextStep)) {
        return nextStep;
    }
    while (nextIndex < this.steps.length) {
      if (PricePointLogic.hasDetailedPoint(pricePoints, nextStep)) return nextStep;
      nextStep = this.steps[++nextIndex];
    }
    return 'quote';
  }
}

@Component({
  selector: 'app-quote-page',
  templateUrl: './quote-page.component.html',
  styleUrls: ['../page.css', './catalog-page.component.css', './quote-page.component.css']
})

export class QuotePageComponent implements OnInit {
  catalogItemIdentifier : string;
  categoryIdentifier : string;
  itemName : string;
  itemFile : string;
  itemAccessoryKeys : Array<string>;
  pricePoints : Array<Object>;
  detailedPricePoints : Array<Object>;
  selections : Object = {};
  title : string = 'Get a Quote';
  subtitle : string = 'Buy Back';
  CARRIER_NAMES = CARRIERS;
  CONDITION_NAMES =  CONDITIONS;
  quoteStep : string = 'loading';
  stateMachine : StateMachine;
  steps : Array<string> = QUOTE_STEPS;
  selectedAccessories : Array<string> = [];
  requireAccessories : boolean = false;

  QUOTE_SELECTIONS = {
    processor: PROCESSORS,
    storage: STORAGES,
    operation: OPERATIONS,
    battery: BATTERIES,
    accessories: ACCESSORIES
  };

  constructor(
    route: ActivatedRoute,
    private catalogItemService : CatalogItemService,
    private pricePointService : PricePointService,
    private changeDetection : ChangeDetectorRef,
    private ngZone : NgZone
  ) {
    this.catalogItemIdentifier = route.snapshot.paramMap.get('catalog_item');
    this.categoryIdentifier = route.snapshot.paramMap.get('category');
    switch(Number(this.categoryIdentifier)) {
      case LAPTOP:
        this.steps = LAPTOP_STEPS;
        break;
      case SMARTPHONE:
        this.steps = PHONE_STEPS;
        break;
      case TABLET:
        this.steps = TABLET_STEPS;
        break;
      case DESKTOP:
        this.steps = DESKTOP_STEPS;
        break;
    }
    this.stateMachine = new StateMachine(this.steps);

    this.handleItem = this.handleItem.bind(this);
    this.handlePricePoints = this.handlePricePoints.bind(this);
  }

  ngOnInit() {
    this.catalogItemService.getItem(this.handleItem, this.catalogItemIdentifier);
    this.pricePointService.getPricePoints(this.handlePricePoints, this.catalogItemIdentifier);
  }

  handlePricePoints(response) {
    this.pricePoints = response;
    const allCarriers = this.carriers();
    if (allCarriers.length == 1) this.selections['carrier'] = allCarriers[0];
    this.quoteStep = this.stateMachine.nextState(this.quoteStep, this.pricePoints, this.selections);
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }

  handleItem(response) {
    this.itemName = response['name'];
    this.itemFile = response['imageFilename'];
    this.itemAccessoryKeys = response['accessoryKeys']?.split(',');
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }

  chooseCarrier(carrierKey) {
    this.selections['carrier'] = carrierKey;
    this.quoteStep = this.stateMachine.nextState(this.quoteStep, this.pricePoints, this.selections);
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }

  chooseCondition(conditionKey) {
    this.selections['condition'] = conditionKey;
    this.pricePointService.getDetailedPricePoints(
      this.catalogItemIdentifier, this.selections['carrier'], this.selections['condition']
    ).subscribe((response: any) => {
      this.detailedPricePoints = response.pricePoints;
      this.goToNextDetailedStep();
    });
  }

  chooseSelection(selection) {
    this.selections[this.quoteStep] = selection;
    this.goToNextDetailedStep();
  }

  goToNextDetailedStep() {
    this.requireAccessories = false;
    if (this.quoteStep === 'accessories'
      && !this.selectionValues().find(x => x === 'N')
      && !this.hasSelectedAccessories()
    ) {
      this.requireAccessories = true;
      return;
    }
    this.quoteStep = this.stateMachine.nextState(this.quoteStep, this.detailedPricePoints, this.selections);
    document.getElementById('step-header').scrollIntoView({ behavior: "smooth" });
  }

  hasSelectedAccessories() {
    return this.selections[this.quoteStep] && this.selections[this.quoteStep].length > 0;
  }

  toggleAccessorySelection(accessoryKey, checked) {
    const currentSelections = this.selections[this.quoteStep] || [];
    if (checked) {
      this.selections[this.quoteStep] = [...currentSelections, accessoryKey];
    } else {
      this.selections[this.quoteStep] = currentSelections.filter(item => item !== accessoryKey);
    }
  }

  stepDisplay() {
    return STEP_DISPLAY_STRINGS[this.quoteStep];
  }

  selectionValues() {
    return PricePointLogic.detailedPoint(this.detailedPricePoints, this.selections, this.quoteStep);
  }

  carriers() {
    return PricePointLogic.carriers(this.pricePoints);
  }

  conditions() {
    return PricePointLogic.conditions(this.pricePoints, this.selections['carrier']);
  }

  quoteNotes(conditionKey) {
    return this.pricePoints.find(pricePoint =>
      (pricePoint['carrierKey'] == null || pricePoint['carrierKey'] == this.selections['carrier'])
      && pricePoint['conditionKey'] == conditionKey
    )['notes'];
  }

  selectedQuote() {
    return this.selectedPricePoint()['price'].toLocaleString('en-US', {style: 'currency', currency: 'USD'});
  }

  selectedPricePointId() {
    return this.selectedPricePoint()['identifier'];
  }

  selectedPricePoint() {
    return PricePointLogic.selectedPricePoint(this.detailedPricePoints, this.selections);
  }
}
