import { Component, OnInit, Input, ChangeDetectorRef, NgZone } from '@angular/core';
import { CatalogCategoryService } from './catalog-category.service';

@Component({
  selector: 'app-catalog-category',
  templateUrl: './catalog-category.component.html',
  styleUrls: ['./catalog-category.component.css']
})

export class CatalogCategoryComponent implements OnInit {
  @Input() categoryIdentifier;
  @Input() linkBase = '#';
  name;
  imageFileName;
  shortDescription;
  subcategoryName;

  constructor(
    private service : CatalogCategoryService,
    private changeDetection : ChangeDetectorRef,
    private ngZone : NgZone
  ) {
    this.handleCategory = this.handleCategory.bind(this);
  }

  handleCategory(response) {
    this.name = response.name;
    this.shortDescription = response.short_description;
    switch(this.categoryIdentifier) {
      case SMARTPHONE:
      this.imageFileName = '/assets/catalog/smartphone.png';
      break;

      case LAPTOP:
      this.imageFileName = '/assets/catalog/laptop.png';
      break;

      case TABLET:
      this.imageFileName = '/assets/catalog/tablet.png';
      break;

      case SMARTWATCH:
      this.imageFileName = '/assets/catalog/smartwatch.png';
      break;

      case DESKTOP:
      this.imageFileName = '/assets/catalog/desktop.png';
      break;

      default:
    }
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }

  ngOnInit() {
    this.service.getCategory(this.categoryIdentifier, this.handleCategory);
  }
}

export const SMARTPHONE = 1;
export const TABLET = 2;
export const FEATURED = 3;
export const LAPTOP = 4;
export const SMARTWATCH = 5;
export const DESKTOP = 6;
