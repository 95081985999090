import { STATUS_SHOPPING } from './customer-order.service';

// workflow statuses
export const STATUS_SUBMITTED = 'C_SUBMITTED';
export const STATUS_SENT_LABELS = 'E_SENT_LABELS';
export const STATUS_EVALUATING = 'E_EVALUATING';
export const STATUS_SENDING_PAYMENT = 'E_SENDING_PAYMENT';
export const STATUS_QUOTE_UPDATED = 'E_UPDATED_QUOTE';
export const STATUS_ACCEPTED = 'C_ACCEPTED_QUOTE';
export const STATUS_CANCELED = 'E_CANCELED';
export const STATUS_CLOSED = 'E_CLOSED';

// model functions
export const F_SUMMARY = 'SUMMARY';
export const F_EMPLOYEE_SUMMARY = 'EMPLOYEE_SUMMARY';
export const F_EMPLOYEE_EDIT = 'EMPLOYEE_EDIT';
export const F_NEW_CUSTOMER_ORDER = 'NEW_CUSTOMER_ORDER';

export class CustomerOrderLogic {

  static itemNameCompator = () => CustomerOrderLogic.simpleComparator('itemName');

  static simpleComparator = fieldName => (
    (a, b) => {
      if (a[fieldName] > b[fieldName]) {
        return 1;
      }
      if (a[fieldName] < b[fieldName]) {
        return -1;
      }
      return 0;
    }
  );

  static startCustomerOrder_item(data) {
    return {
      thumbFile: data.itemImageFile,
      itemName: data.catalogItemName,
      quantity: 1,
      carrierKey: data.carrierKey,
      conditionKey: data.conditionKey,
    };
  }

  static updateOrderStatus_statusDescription(statusKey, isShip = false, isExpedite = false) {
    let statusDescription = '';
    switch (statusKey) {
      case STATUS_SUBMITTED:
        if (isShip) {
          if (isExpedite) statusDescription = 'Your order has been submited.  Please allow up to four business days to receive your labels by mail.';
          else statusDescription = 'Your order has been submited.  Please allow up to two weeks to receive your labels by mail.';
        }
        else statusDescription = 'Your order has been submitted. Please come in to <a href="https://ifixnsell.net/contact" target="_blank">one of our convenient locations</a> along with your device.';
        break;
      case STATUS_SENT_LABELS:
        statusDescription = 'Your shipping labels have been sent.  Please wait 5-10 business days for them to arrive.';
        break;
      case STATUS_ACCEPTED:
      case STATUS_EVALUATING:
        statusDescription = 'We are currently evaluating your order.  We will provide you with an update soon.';
        break;
      case STATUS_SENDING_PAYMENT:
        statusDescription = 'Your order has been accepted and payment is on its way!';
        break;
      case STATUS_QUOTE_UPDATED:
        statusDescription = 'Your quote has been updated.  Please review and accept or contact us for any questions.';
        break;
    }
    return statusDescription;
  }

  static getCustomerOrder_items(orderItems) {
    let items = {}
    orderItems.forEach(orderItem => {
      const newItem = CustomerOrderLogic.customerOrderItem(
        orderItem.price_point_identifier,
        orderItem.catalog_item_identifier,
        orderItem.item_image,
        orderItem.item_name,
        orderItem.quantity,
        orderItem.brand_name,
        orderItem.processor,
        orderItem.storage,
        orderItem.operation,
        orderItem.battery,
        orderItem.accessories,
        orderItem.carrier_key,
        orderItem.condition_key,
        orderItem.price
      );
      items = { ...items, ...newItem };
    });
    return items;
  }

  static customerOrderItem(
    pricePointIdentifier,
    itemId,
    thumbFile,
    itemName,
    quantity,
    brandName,
    processor,
    storage,
    operation,
    battery,
    accessoriesList,
    carrierKey,
    conditionKey,
    price
  ) {
    const accessories = accessoriesList ? accessoriesList.split(',') : [];
    return {
      [pricePointIdentifier]: {
        itemId,
        thumbFile,
        itemName,
        quantity,
        brandName,
        processor,
        storage,
        operation,
        battery,
        accessories,
        carrierKey,
        conditionKey,
        price
      }
    };
  }

  static statusSummary(statusKey, isShip, isExpedite) {
    let summary;
    switch (statusKey) {
      case STATUS_SUBMITTED:
        summary = isShip ? 'Awaiting Shipping Labels' : 'Order Submitted';
        break;
      case STATUS_SENT_LABELS:
        summary = 'Shipping Labels Sent';
        break;
      case STATUS_EVALUATING:
        summary = 'Evaluating Your Order';
        break;
      case STATUS_SENDING_PAYMENT:
        summary = 'Sending Payment';
        break;
      case STATUS_QUOTE_UPDATED:
        summary = 'Quote Updated';
        break;
      case STATUS_ACCEPTED:
        summary = 'Quote Accepted';
        break;
      case STATUS_SHOPPING:
      default:
        summary = 'Still Browsing';
    }
    if (isExpedite) summary = '*EXPEDITE* ' + summary;
    return summary;
  }

  static editItemDialog_pricePoint(item, pricePointId, pricePoints) {
    const pricePoint = pricePoints.find(pp => pp.identifier == pricePointId);
    return CustomerOrderLogic.itemDialog_pricePoint(item, pricePoint.conditionKey, pricePoint.carrierKey);
  }

  static itemDialog_pricePoint(item, conditionKey, carrierKey, quantity = undefined) {
    return { ...item, conditionKey, carrierKey, quantity };
  }

  static statusChangeDialog_data(canCancel : boolean, currentStatus : string) {
    return {
      [STATUS_SUBMITTED]: 'Order Submitted',
      [STATUS_SENT_LABELS]: 'Sent Shipping Labels',
      [STATUS_EVALUATING]: 'Evaluating Order',
      [STATUS_SENDING_PAYMENT]: 'Sending Payment to Customer',
      ...(canCancel && { [STATUS_CANCELED]: 'Cancel Order' }),
      ...(currentStatus === STATUS_SENDING_PAYMENT && { [STATUS_CLOSED]: 'Close Order' })
    };
  }

  static prepareUpdateOrderOperations(editProgressItems, editedItems, addedItems, removedItems) {
    const operations = [];
    editedItems.forEach(entry => {
      operations.push({ ppid: entry, quantity: editProgressItems[entry].quantity, op: 'edit' });
    })
    addedItems.forEach(entry => {
      operations.push({ ppid: entry, quantity: editProgressItems[entry].quantity, op: 'add' });
    })
    removedItems.forEach(entry => {
      operations.push({ ppid: entry, op: 'remove' });
    })
    return operations;
  }

  static executeUpdateOrderItems(op, cb, customerOrderService, orderNumber) {
    if (op.op == 'remove') {
      customerOrderService.removeItems(orderNumber, op.ppid, cb);
    } else if (op.op == 'edit') {
      customerOrderService.updateOrderItem(orderNumber, op.ppid, op.quantity, cb);
    } else {
      customerOrderService.addOrderItem(orderNumber, op.ppid, op.quantity, null, cb);
    }
  }

}
