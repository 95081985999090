import { Injectable } from '@angular/core';
import rp from 'request-promise';
import { environment } from '../../../environments/environment';
import { CustomerGuidService } from './customer-guid.service';

const serverAdx = environment.apiHost;

export const STATUS_SHOPPING = 'C_SHOPPING';

@Injectable()
export class CustomerOrderService {
  constructor(private guidService : CustomerGuidService) {}

  startCustomerOrder(pricePointId, accessories, callback) {
    const options = {
      method: 'POST',
      uri: `${serverAdx}/api/workflow`,
      body: { price_point: pricePointId, accessories, cartIdentifier: this.guidService.getCartIdentifier() },
      json: true,
      withCredentials: true
    };
    return rp(options).promise().bind(this)
      .then(responseBody => callback(true, responseBody.order))
      .catch(function () {
        // const responseBody = errorObject.response.body;
        return callback(false);
      });
  }

  removeItems(orderNumber, pricePointId, callback) {
    const options = {
      method: 'DELETE',
      uri: `${serverAdx}/api/workflow/${orderNumber}/pricepoint/${pricePointId}`,
      json: true,
      withCredentials: true
    };
    return rp(options).promise().bind(this)
      .then(responseBody => callback(true, responseBody.order_removed, pricePointId)) // return to resolve chain warning
      .catch(function () {
        // const responseBody = errorObject.response.body;
        callback(false);
      });
  }

  addOrderItem(orderNumber, pricePoint, quantity, accessories, callback) {
    const options = {
      method: 'PUT',
      uri: `${serverAdx}/api/workflow`,
      body: {
        orderNumber: orderNumber,
        pricePoint,
        cartIdentifier: this.guidService.getCartIdentifier(),
        quantity,
        accessories
      },
      json: true,
      withCredentials: true
    };
    return rp(options).promise().bind(this)
      .then(() => callback(true)) // return to resolve chain warning
      .catch(function () {
        // const responseBody = errorObject.response.body;
        return callback(false);
      });
  }

  updateOrderItem(orderNumber, pricePoint, updatedQuantity, callback) {
    const options = {
      method: 'PUT',
      uri: `${serverAdx}/api/workflow`,
      body: { orderNumber: orderNumber, pricePoint, updatedQuantity },
      json: true,
      withCredentials: true
    };
    return rp(options).promise().bind(this)
      .then(() => callback(true)) // return to resolve chain warning
      .catch(function () {
        // const responseBody = errorObject.response.body;
        callback(false);
      });
  }

  updateOrderStatus(orderNumber, action, callback) {
    const options = {
      method: 'PUT',
      uri: `${serverAdx}/api/workflow`,
      body: { orderNumber: orderNumber, action, cartIdentifier: this.guidService.getCartIdentifier() },
      json: true,
      withCredentials: true
    };
    return rp(options).promise().bind(this)
      .then(() => callback(true, action))
      .catch(function () {
        // const responseBody = errorObject.response.body;
        return callback(false);
      });
  }

  updateOrderStatusShipping(orderNumber, action, paymentMethod, paymentContact, isShip, isExpedite, isInsure, callback) {
    const options = {
      method: 'PUT',
      uri: `${serverAdx}/api/workflow`,
      body: {
        orderNumber: orderNumber,
        action,
        paymentMethod,
        paymentContact,
        shipping: isShip ? 'yes' : 'no',
        expedite: isExpedite ? 'yes' : 'no',
        insure: isInsure ? 'yes' : 'no',
        cartIdentifier: this.guidService.getCartIdentifier()
      },
      json: true,
      withCredentials: true
    };
    return rp(options).promise().bind(this)
      .then(() => callback(true, action))
      .catch(function () {
        // const responseBody = errorObject.response.body;
        return callback(false);
      });
  }

  customerOrderNotification(orderNumber, eventType) {
    const options = {
      method: 'POST',
      uri: `${serverAdx}/api/workflow/${orderNumber}/notifcation`,
      body: { eventType },
      json: true,
      withCredentials: true
    };
    return rp(options).promise().bind(this).then(() => {}).catch(() => {});
  }

  getCustomersOrders(customerIdentifier, callback) {
    return rp(`${serverAdx}/api/workflow?customer_identifier=${customerIdentifier}`).promise().bind(this)
      .then(function (responseBody) {
          return callback(customerIdentifier, JSON.parse(responseBody).orders);
      }).catch(() => {});
  }

  getCustomerOwnOrders(callback) {
    const cartId = this.guidService.getCartIdentifier();
    return rp(`${serverAdx}/api/workflow?cartIdentifier=${cartId}`).promise().bind(this)
      .then(function (responseBody) {
          return callback(JSON.parse(responseBody).orders);
      }).catch(() => {});
  }

  getCustomerOrder(orderNumber, callback) {
    const cartId = this.guidService.getCartIdentifier();
    return rp(`${serverAdx}/api/workflow/${orderNumber}?cartIdentifier=${cartId}`).promise().bind(this)
      .then(responseBody => callback(JSON.parse(responseBody).order)).catch(() => {});
  }

  loadOrderNote(orderNumber, callback) {
    return rp(`${serverAdx}/api/workflow/${orderNumber}/note/individual_buyback`).promise().bind(this)
      .then(responseBody => callback(true, JSON.parse(responseBody).note)).catch(() => {});
  }

  updateOrderNote(orderNumber, workflowNote, callback) {
    const options = {
      method: 'PUT',
      uri: `${serverAdx}/api/workflow/${orderNumber}/note/individual_buyback`,
      body: { workflowNote },
      json: true,
      withCredentials: true
    };
    return rp(options).promise().bind(this).then(() => callback(true)).catch(() => {});
  }

  inactivateOrder(orderNumber, callback) {
    const options = {
      method: 'POST',
      uri: `${serverAdx}/api/workflow/${orderNumber}/inactivate`,
      body: {},
      json: true,
      withCredentials: true
    };
    return rp(options).promise().bind(this).then(() => callback());
  }
}
