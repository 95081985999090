import { Component, OnInit, NgZone, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerOrderService, STATUS_SHOPPING } from '../../models/customer-order/customer-order.service';

@Component({
  selector: 'app-order-summary-page',
  templateUrl: './summary-page.component.html',
  styleUrls: ['../page.css', './summary-page.component.css']
})

export class SummaryPageComponent implements OnInit {
  pricePointIdentifier : string;
  existingOrderNumber  : string;
  title : string = 'Your Orders';
  subtitle : string = 'Buy Back';
  loadingOrders = true;
  payMethod: string;
  payContact: string;
  isShip = false;
  isExpedite = false;
  isInsure = false;
  accessories : Array<string>;

  constructor(
    route: ActivatedRoute,
    private customerOrderService: CustomerOrderService,
    private ngZone : NgZone,
    private changeDetection : ChangeDetectorRef
  ) {
    this.pricePointIdentifier = route.snapshot.paramMap.get('price_point');
    this.payMethod = route.snapshot.paramMap.get('pay');
    this.payContact = route.snapshot.paramMap.get('pay_contact');
    this.isShip = route.snapshot.paramMap.get('ship') === 'true';
    this.isExpedite = route.snapshot.paramMap.get('expedite') === 'true';
    this.isInsure = route.snapshot.paramMap.get('insure') === 'true';
    this.accessories = route.snapshot.queryParamMap.getAll('accessories');

    this.handleOrders = this.handleOrders.bind(this);
  }

  handleOrders(orders) {
    const orderKey = Object.keys(orders).find(orderKey => STATUS_SHOPPING === orders[orderKey].status_key);
    if (orderKey) this.existingOrderNumber = orderKey;
    this.loadingOrders = false;
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }

  ngOnInit() {
    this.customerOrderService.getCustomerOwnOrders(this.handleOrders);
  }
}
