import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';

import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';

//import { QuoteGuard } from './auth/quote.guard';
//import { OrderSummaryGuard } from './auth/order-summary.guard';
import { EmployeeViewOrdersGuard } from './auth/employee-view-orders.guard';
import { ManageCatalogGuard } from './auth/manage-catalog.guard';

import { AppComponent } from './app.component';
import { HomePageComponent } from './page/home/home-page.component';
import { CatalogCategoryComponent } from './models/catalog-category/catalog-category.component';
import { CatalogItemComponent } from './models/catalog-item/catalog-item.component';
import { CatalogPageComponent } from './page/catalog/catalog-page.component';
import { QuotePageComponent } from './page/catalog/quote-page.component';
import { SearchPageComponent } from './page/catalog/search-page.component';
import { SigninPageComponent } from './page/account/signin-page.component';
import { SignupPageComponent } from './page/account/signup-page.component';
import { CustomerComponent} from './models/customer/customer.component';
import { AuthorityComponent } from './models/authority/authority.component';
import { SummaryPageComponent } from './page/order/summary-page.component';
import { OrderReviewPageComponent } from './page/order/review-page.component';
import { OrderOptionsPageComponent } from './page/order/options-page.component';
import { CustomerOrderComponent } from './models/customer-order/customer-order.component';
import { StatusChangeDialog } from './models/customer-order/customer-order-status-change-dialog.component';
import { EditItemDialog } from './models/customer-order/customer-order-edit-item-dialog.component';
import { AddItemDialog } from './models/customer-order/customer-order-add-item-dialog.component';
import { OrderNoteDialog } from './models/customer-order/customer-order-note-dialog.component';
import { EmployeeSigninPageComponent } from './page/employee/account/signin-page.component';
import { EmployeeSummaryPageComponent } from './page/employee/order/summary-page.component';
import { EmployeeViewOrderPageComponent } from './page/employee/order/view-order-page.component';
import { ManageCatalogPageComponent } from './page/employee/catalog/manage-catalog-page.component';
import { PriceChangeComponent } from './page/employee/catalog/price-change-page.component';
import { MainHeaderComponent } from './ui/main-header.component';
import { MainFooterComponent } from './ui/main-footer.component';
import { ContentPageComponent } from './page/static/content-page.component';

import { NavMenuWidget } from './page/employee/widget/nav-menu.component';

import { CatalogItemService } from './models/catalog-item/catalog-item.service';
import { CatalogCategoryService } from './models/catalog-category/catalog-category.service';
import { CatalogBrandService } from './models/catalog-brand/catalog-brand.service';
import { PricePointService } from './models/price-point/price-point.service';
import { AuthorityFunctionService } from './auth/authority-function.service';
import { AuthorityService } from './models/authority/authority.service';
import { CustomerService } from './models/customer/customer.service';
import { CustomerOrderService } from './models/customer-order/customer-order.service';
import { CustomerGuidService } from './models/customer-order/customer-guid.service';

import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

import { NgxWebstorageModule } from 'ngx-webstorage';


const appRoutes : Routes = [
  { path: '', component: HomePageComponent, pathMatch: 'full' },
  { path: 'home', component: HomePageComponent, pathMatch: 'full' },
  { path: 'catalog/search', component: SearchPageComponent },
  { path: 'catalog/:category', component: CatalogPageComponent },
  { path: 'catalog/:category/brand/:brand', component: CatalogPageComponent },
  { path: 'signin', component: SigninPageComponent },
  { path: 'signup', component: SignupPageComponent },
  { path: 'catalog/quote/:catalog_item/:category', component: QuotePageComponent },
  { path: 'order_summary', component: SummaryPageComponent },
  { path: 'order_summary/:price_point', component: SummaryPageComponent },
  { path: 'order_options', component: OrderOptionsPageComponent },
  { path: 'order_review/:order_number', component: OrderReviewPageComponent },
  { path: 'information/:page_key', component: ContentPageComponent },
  { path: 'eo', component: EmployeeSigninPageComponent },
  { path: 'eo/dashboard', component: EmployeeSummaryPageComponent, canActivate: [EmployeeViewOrdersGuard] },
  { path: 'eo/order/:order_number', component: EmployeeViewOrderPageComponent, canActivate: [EmployeeViewOrdersGuard] },
  { path: 'eo/manage_catalog', component: ManageCatalogPageComponent, canActivate: [ManageCatalogGuard] },
  { path: 'eo/manage_catalog/prices', component: PriceChangeComponent, canActivate: [ManageCatalogGuard] },
  { path: '**', redirectTo: 'boom' } // TODO: 404 page or something
];

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    CatalogCategoryComponent,
    CatalogItemComponent,
    CatalogPageComponent,
    SearchPageComponent,
    QuotePageComponent,
    SigninPageComponent,
    SignupPageComponent,
    CustomerComponent,
    AuthorityComponent,
    SummaryPageComponent,
    OrderReviewPageComponent,
    CustomerOrderComponent,
    EmployeeSigninPageComponent,
    EmployeeSummaryPageComponent,
    EmployeeViewOrderPageComponent,
    StatusChangeDialog,
    ManageCatalogPageComponent,
    EditItemDialog,
    PriceChangeComponent,
    AddItemDialog,
    NavMenuWidget,
    OrderNoteDialog,
    MainHeaderComponent,
    MainFooterComponent,
    ContentPageComponent,
    OrderOptionsPageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy', scrollPositionRestoration: 'enabled' }),
    FormsModule,
    ReactiveFormsModule,
    NgxWebstorageModule.forRoot(),
    RecaptchaModule,
    RecaptchaFormsModule,
    NgbModule,
    NgbCarouselModule,
    MatCardModule,
    MatButtonModule,
    MatGridListModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatListModule,
    MatDialogModule,
    MatMenuModule,
    MatTabsModule,
    MatRadioModule
  ],
  providers: [
    // Services
    CatalogCategoryService,
    CatalogItemService,
    CatalogBrandService,
    PricePointService,
    AuthorityFunctionService,
    AuthorityService,
    CustomerService,
    CustomerOrderService,
    CustomerGuidService,
    // Route Guards
    EmployeeViewOrdersGuard,
    ManageCatalogGuard
  ],
  entryComponents: [
    StatusChangeDialog,
    EditItemDialog,
    AddItemDialog,
    OrderNoteDialog
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
