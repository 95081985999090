import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorityFunctionService } from './authority-function.service';
import { AuthorityFunctionGuard } from './authority-function.guard';


@Injectable()
export class EmployeeViewOrdersGuard extends AuthorityFunctionGuard {
  constructor(private aService : AuthorityFunctionService, private aRouter: Router, private aNgZone: NgZone) {
    super('employee_view_orders', aService, aRouter, aNgZone);
  }
}
