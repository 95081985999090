import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-catalog-item',
  templateUrl: './catalog-item.component.html',
  styleUrls: ['./catalog-item.component.css']
})

export class CatalogItemComponent {
  @Input() brand;
  @Input() brandIdentifier;
  @Input() identifier;
  @Input() name;
  @Input() imageFile;
  @Input() shortDescription;
  @Input() longDescription;
  @Input() actionPath;
  @Input() actionParams;
  @Input() actionText;
}
