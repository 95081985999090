import { Injectable, NgZone } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthorityFunctionService } from './authority-function.service';

@Injectable()
export class AuthorityFunctionGuard implements CanActivate {
  constructor(
    private functionKey : string,
    private service : AuthorityFunctionService,
    private router: Router,
    private ngZone: NgZone,
    private authRedirectPath : string = undefined
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.service.isAuthorized(this.functionKey).then(
      authd => {
        if (!authd) {
          const nav = this.authRedirectPath ? [this.authRedirectPath, { dest: state.url }] : ['/home'];
          this.ngZone.run(() => this.router.navigate(nav)).then();
        }
        return authd;
      }
    );
  }
}
