import { Injectable } from '@angular/core';
import rp from 'request-promise';
import { environment } from '../../../environments/environment';
import { CustomerGuidService } from '../customer-order/customer-guid.service';

const serverAdx = environment.apiHost;

@Injectable()
export class AuthorityService {
  constructor(private guidService : CustomerGuidService) {}

  signInUser(userName, password, recaptchaData, isEmployee, callback) {
    const options = {
      method: 'POST',
      uri: `${serverAdx}/api/authority/signin`,
      body: {
        user_name: userName,
        raw_password: password,
        'g-recaptcha-response': recaptchaData,
        is_employee: isEmployee,
        cart_identifier: this.guidService.getCartIdentifier()
      },
      json: true,
      withCredentials: true
    };
    rp(options).promise().bind(this)
      .then(function () { // responseBody
        callback(true);
      })
      .catch(function (errorObject) {
        const retriesExceeded = errorObject.response ? errorObject.response.retriesExceeded : true;
        callback(false, retriesExceeded);
      });
  }

  getAuthority(callback) {
    const options = {
      method: 'GET',
      uri: `${serverAdx}/api/authority`,
      json: true,
      withCredentials: true
    };
    rp(options).promise().bind(this)
      .then(function (responseBody) {
        callback(responseBody);
      })
      .catch(function () { // errorObject
        callback();
      });
  }


  getFunctions(callback) {
    const options = {
      method: 'GET',
      uri: `${serverAdx}/api/authority/function`,
      json: true,
      withCredentials: true
    };
    rp(options).promise().bind(this)
      .then(function (responseBody) {
        callback(responseBody.functions.allow);
      })
      .catch(function () { // errorObject
        callback([]);
      });
  }

}
