import { Component, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CustomerService } from '../../models/customer/customer.service';
import {
  CU_FIRST_NAME,
  CU_LAST_NAME,
  CU_PHONE,
  CU_EMAIL,
  CU_STREET_1,
  CU_STREET_2,
  CU_CITY,
  CU_STATE,
  CU_ZIPCODE
} from '../../models/customer/customer.constants';
import { AU_USER, AU_PASS, AU_PASS_C } from '../../models/authority/authority.component';

@Component({
  selector: 'app-signup-page',
  templateUrl: './signup-page.component.html',
  styleUrls: ['../page.css', './signin-page.component.css'],
})

export class SignupPageComponent implements OnInit {
  subtitle : string = 'Sign Up';
  signUpErrorMessage : string = null;
  formSubmitted : boolean = false;
  signupForm : FormGroup = null;
  isOrderFlow = false;
  showLoginForm = true;
  showLoginSubscription;
  isShip = false;
  isExpedite = false;
  isInsure = false;
  payMethod : string;
  payContact : string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private customerService : CustomerService,
    private changeDetection : ChangeDetectorRef,
    private ngZone : NgZone
  ) {
    this.handleCustomerCreated = this.handleCustomerCreated.bind(this);
  }

  toggleLoginForm() {
    this.showLoginForm = !this.showLoginForm;
  }

  ngOnInit() {
    const flowParam = this.route.snapshot.paramMap.get('flow');
    if ('order' === flowParam) {
      this.isOrderFlow = true;
      this.showLoginForm = false;
    }
    this.payMethod = this.route.snapshot.paramMap.get('pay');
    this.payContact = this.route.snapshot.paramMap.get('pay_contact') || '';
    this.isShip = this.route.snapshot.paramMap.get('ship') === 'true';
    this.isExpedite = this.route.snapshot.paramMap.get('expedite') === 'true';
    this.isInsure = this.route.snapshot.paramMap.get('insure') === 'true';
    this.signupForm =  new FormGroup({
      orderFlowGroup: new FormGroup({
        toggleLoginControl: new FormControl(this.showLoginForm),
      }),
      addAuthorityGroup: new FormGroup({
        [AU_USER]: new FormControl('', this.showLoginForm && Validators.required),
        [AU_PASS]: new FormControl(
          '',
          this.showLoginForm && [
            Validators.required,
            Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[A-Za-z\\d@$!%*?&#]{8,}$')
          ]
        ),
        [AU_PASS_C]: new FormControl('', this.showLoginForm && Validators.required)
      },
      Validators.compose([passwordMatch()])),
      addCustomerGroup: new FormGroup({
        [CU_FIRST_NAME]: new FormControl('', Validators.required),
        [CU_LAST_NAME]: new FormControl('', Validators.required),
        [CU_PHONE]: new FormControl('', Validators.pattern('^\\d{3}-?\\d{3}-?\\d{4}$')),
        [CU_EMAIL]: new FormControl('', [Validators.required, Validators.email]),
        [CU_STREET_1]: new FormControl('', Validators.required),
        [CU_STREET_2]: new FormControl(''),
        [CU_CITY]: new FormControl('', Validators.required),
        [CU_STATE]: new FormControl('', Validators.required),
        [CU_ZIPCODE]: new FormControl('', [Validators.required, Validators.pattern('^\\d{5}$')])
      })
    });
    if (this.isOrderFlow) this.trackLoginShow();
  }

  trackLoginShow() {
    this.showLoginSubscription = this.signupForm.get('orderFlowGroup').get('toggleLoginControl').valueChanges.subscribe(checked => {
      if (checked) {
        this.signupForm.get('addAuthorityGroup').get(AU_USER).setValidators([Validators.required]);
        this.signupForm.get('addAuthorityGroup').get(AU_PASS).setValidators(
          [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[A-Za-z\\d@$!%*?&#]{8,}$')]
        );
        this.signupForm.get('addAuthorityGroup').get(AU_PASS_C).setValidators([Validators.required]);
      } else {
        this.signupForm.get('addAuthorityGroup').get(AU_USER).setValidators(null);
        this.signupForm.get('addAuthorityGroup').get(AU_PASS).setValidators(null);
        this.signupForm.get('addAuthorityGroup').get(AU_PASS_C).setValidators(null);
      }
      this.signupForm.get('addAuthorityGroup').get(AU_USER).updateValueAndValidity();
      this.signupForm.get('addAuthorityGroup').get(AU_PASS).updateValueAndValidity();
      this.signupForm.get('addAuthorityGroup').get(AU_PASS_C).updateValueAndValidity();
    });
  }

  handleCustomerCreated(success, failReason) {
    if (success) {
      if (this.isOrderFlow && !this.showLoginForm) {
        this.ngZone.run(
          () => {
            this.router.navigate(
              [
                '/order_summary',
                {
                  pay: this.payMethod,
                  pay_contact: this.payContact,
                  ship: this.isShip,
                  expedite: this.isExpedite,
                  insure: this.isInsure
                }
              ]
            );
          }
        );
      } else {
        const params = { message_key: 'signed_up', dest: '/order_summary' };
        if (this.isOrderFlow) {
          params['flow'] = 'order';
          params['pay'] = this.payMethod;
          params['pay_contact'] = this.payContact;
          params['ship'] = this.isShip;
          params['expedite'] = this.isExpedite;
          params['insure'] = this.isInsure;
        }
        this.ngZone.run(() => { this.router.navigate(['/signin', params]); });
      }
    } else {
      if ('EMAIL_EXISTS' === failReason) this.signUpErrorMessage =
        'Email addressed already registered.  Sign in or use a different email address.';
      else if ('USERNAME_EXISTS' === failReason) this.signUpErrorMessage =
        'Username addressed already registered.  Sign in or use a different username.';
      else this.signUpErrorMessage =
        `We're sorry, something went wrong. Please try again or contact us for assistance: ${environment.supportPhone}`;
      this.formSubmitted = false;
      this.ngZone.run(() => { this.changeDetection.detectChanges() });
    }
  }

  submitForm() {
    [AU_USER, AU_PASS, AU_PASS_C].forEach((key) => {
      this.signupForm.get('addAuthorityGroup').get(key).markAsTouched();
    });
    [
      CU_FIRST_NAME, CU_LAST_NAME, CU_PHONE, CU_EMAIL, CU_STREET_1, CU_STREET_2, CU_CITY, CU_STATE, CU_ZIPCODE
    ].forEach((key) => { this.signupForm.get('addCustomerGroup').get(key).markAsTouched(); });
    if (this.signupForm.valid) {
      this.signUpErrorMessage = null;
      this.formSubmitted = true;
      this.customerService.addCustomer(
        this.signupForm.value.addCustomerGroup,
        this.signupForm.value.addAuthorityGroup[AU_USER],
        this.signupForm.value.addAuthorityGroup[AU_PASS],
        this.handleCustomerCreated
      );
    } else {
      // console.log('not valid');
    }
  }

  goToSignIn(event) {
    event.preventDefault();
    this.router.navigate(
      [
        '/signin',
        {...(
          this.isOrderFlow
          && {
            flow: 'order',
            pay: this.payMethod,
            pay_contact: this.payContact,
            ship: this.isShip,
            expedite: this.isExpedite,
            insure: this.isInsure
          }
        )}
      ]
    );
  }

  ngOnDestroy() {
    if (this.showLoginSubscription) this.showLoginSubscription.unsubscribe();
  }
}

function passwordMatch(): ValidatorFn {
  return (authFormGroup) => {
    const pass = authFormGroup.get(AU_PASS).value;
    const passConf = authFormGroup.get(AU_PASS_C).value
    if (!pass || !passConf) return null;
    if (pass !== passConf) {
      authFormGroup.get(AU_PASS_C).setErrors({ passwordMatch: true });
      return { passwordMatch: true };
    }
    return null;
  }
}
