import { Component, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerOrderService } from '../../../models/customer-order/customer-order.service';

@Component({
  selector: 'app-employee-view-order-page',
  templateUrl: './view-order-page.component.html',
  styleUrls: ['../../page.css', './view-order-page.component.css']
})

export class EmployeeViewOrderPageComponent implements OnInit {
  title : string = 'Manage Orders';
  subtitle : string = 'View Customer Orders';
  orderNumber: string;
  customerName: string;
  customerIdentifier: string;

  constructor(
    private route: ActivatedRoute,
    private customerOrderService : CustomerOrderService,
    private changeDetection : ChangeDetectorRef,
    private ngZone : NgZone
  ) {
    this.handleCustomerOrder = this.handleCustomerOrder.bind(this);
    this.orderNumber = this.route.snapshot.paramMap.get('order_number');
  }

  handleCustomerOrder(order) {
    this.customerName = order.customer_lastname + ', ' + order.customer_firstname;
    this.customerIdentifier = order.customer_identifier;
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }

  ngOnInit() {
    this.customerOrderService.getCustomerOrder(this.orderNumber, this.handleCustomerOrder);
  }
}
