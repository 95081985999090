import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { STATUS_CANCELED, STATUS_CLOSED } from './customer-order.logic';

@Component({
  selector: 'customer-order-status-change-dialog',
  templateUrl: 'customer-order-status-change-dialog.html',
  styleUrls: ['./customer-order-dialog_shared.component.css']
})

export class StatusChangeDialog {
  newStatusKey = '';

  constructor(
    public dialogRef: MatDialogRef<StatusChangeDialog>,
    @Inject(MAT_DIALOG_DATA) public statusOptions
  ) {}

  statusOptionKeys() {
    return Object.keys(this.statusOptions);
  }

  selectStatus() {
    let confirmed = true;
    if (STATUS_CANCELED === this.newStatusKey) {
      confirmed = confirm('Are you sure you want to cancel the order?')
    } else if (STATUS_CLOSED === this.newStatusKey) {
      confirmed = confirm('Have all devices been received and payment sent?')
    }
    if (confirmed) this.dialogRef.close(this.newStatusKey);
  }

  statusKeyChange(newValue) {
    this.newStatusKey = newValue;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
