import { Injectable } from '@angular/core';
import rp from 'request-promise';
import { environment } from '../../../environments/environment';

const serverAdx = environment.apiHost;

@Injectable()
export class CatalogCategoryService {
  getCategory(category, callback) {
    rp(`${serverAdx}/api/catalog/category/${category}`).promise().bind(this)
      .then(function (responseBody) {
          const catalogItems = JSON.parse(responseBody);
          callback(catalogItems['category']);
      }).catch(() => {});
  }
}
