import { Component, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import {
  LAPTOP, SMARTPHONE, SMARTWATCH, TABLET, FEATURED
} from '../../models/catalog-category/catalog-category.component';
import { CatalogItemService } from '../../models/catalog-item/catalog-item.service';
import { CatalogBrandService } from '../../models/catalog-brand/catalog-brand.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['../page.css', './home-page.component.css']
})

export class HomePageComponent implements OnInit {
  // categories = [SMARTPHONE, TABLET, LAPTOP, SMARTWATCH];
  categories = [SMARTPHONE, TABLET];
  featuredItems = [];
  brands = {};
  customPanelClass = `Panel-section--${this.categories.length}`;

  constructor(
    private itemService : CatalogItemService,
    private catalogBrandService : CatalogBrandService,
    private changeDetection : ChangeDetectorRef,
    private ngZone : NgZone
  ) {
    this.handleFeatured = this.handleFeatured.bind(this);
    this.handleBrands = this.handleBrands.bind(this);
  }

  handleFeatured(items) {
    const brandsLoaded = Object.keys(this.brands).length > 0;
    this.featuredItems = items.map(item => ({
      brandName: brandsLoaded ? this.brands[item.brandIdentifier] : '',
      ...item
    }));
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }

  handleBrands(someBrands) {
    someBrands.forEach((brand) => { this.brands[brand.identifier] = brand.name; });
    if (this.featuredItems.length) {
      this.featuredItems.forEach((item) => {
        item.brandName = this.brands[item.brandIdentifier];
      });
    }
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }

  ngOnInit() {
    this.itemService.getFeaturedItems(this.handleFeatured);
    this.catalogBrandService.getBrands(this.handleBrands, FEATURED);
  }
}
