import { Component, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthorityService } from '../../models/authority/authority.service';
import { AU_USER, AU_PASS, AU_CAPTCHA } from '../../models/authority/authority.component';

const infoMessages = {
  signed_up: 'Thank you for registering!  Please sign in to your account.'
};

@Component({
  selector: 'app-order-options-page',
  templateUrl: './options-page.component.html',
  styleUrls: ['../page.css', './options-page.component.css']
})

export class OrderOptionsPageComponent implements OnInit {
  subtitle : string = 'Order Options';
  infoMessage : string;
  optionsForm : FormGroup;
  formSubmitted : boolean = false;
  isShip : boolean = false;
  payMethod : string;
  payContact : string;
  isPhoneContact : boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService : AuthorityService,
    private snackBar: MatSnackBar,
    private changeDetection : ChangeDetectorRef,
    private ngZone: NgZone
  ) {
    this.handleLoginCheck = this.handleLoginCheck.bind(this);
  }

  ngOnInit() {
    this.optionsForm =  new FormGroup({
      shipControl: new FormControl(null, Validators.required),
      payMethodControl: new FormControl(null, Validators.required),
      zelleContactControl: new FormControl(null)
    });
    this.optionsForm.get('shipControl').valueChanges.forEach(value => {
      if ('true' === value) {
        this.optionsForm.addControl('expediteControl', new FormControl('false'));
        this.optionsForm.addControl('insureControl', new FormControl(null, Validators.required));
      } else {
        this.optionsForm.removeControl('expediteControl');
        this.optionsForm.removeControl('insureControl');
      }
      this.isShip = 'true' === value;
    });
    this.optionsForm.get('payMethodControl').valueChanges.forEach(value => {
      this.payMethod = value;
      this.optionsForm.removeControl('payContactControl');
      if (this.payMethod === 'paypal') {
          this.optionsForm.addControl(
            'payContactControl', new FormControl(null, [Validators.required, Validators.email])
          );
      } else if (this.payMethod === 'zelle') {
        const validators = [Validators.required];
        if (!this.isPhoneContact) validators.push(Validators.email);
        this.optionsForm.addControl('payContactControl', new FormControl(null, validators));
      }
    });
    this.optionsForm.get('zelleContactControl').valueChanges.forEach(value => {
      this.isPhoneContact = value === 'phone';
      const newValidators = [Validators.required];
      if (!this.isPhoneContact) newValidators.push(Validators.email);
      this.optionsForm.get('payContactControl').setValidators(newValidators);
      this.optionsForm.get('payContactControl').updateValueAndValidity();
    });
  }

  handleLoginCheck(authResponse) {
    const params = this.formParams();
    if (authResponse && authResponse.as) {
      this.ngZone.run(() => this.router.navigate(['/order_summary', params]));
    } else {
      this.ngZone.run(() => {
        this.router.navigate(
          ['/signup', { ...params, flow: 'order' }]
        );
      });
    }
  }

  formParams() {
    return {
      pay: this.optionsForm.controls['payMethodControl'].value,
      ship: this.optionsForm.controls['shipControl'].value,
      ...(
        this.optionsForm.controls['payContactControl']
        && { pay_contact: this.optionsForm.controls['payContactControl'].value }
      ),
      ...(
        this.optionsForm.controls['expediteControl']
        && { expedite: this.optionsForm.controls['expediteControl'].value }
      ),
      ...(
        this.optionsForm.controls['insureControl'] &&
        { insure: this.optionsForm.controls['insureControl'].value }
      )
    };
  }

  submitForm() {
    this.optionsForm.markAllAsTouched();
    if (this.optionsForm.valid) {
      this.formSubmitted = true;
      this.authService.getAuthority(this.handleLoginCheck);
    }
  }
}
