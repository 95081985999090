import { Component, Input, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthorityFunctionService } from '../../auth/authority-function.service';
import { CustomerOrderService, STATUS_SHOPPING } from './customer-order.service';
import {
  CARRIERS, CONDITIONS, PROCESSORS, STORAGES, OPERATIONS, BATTERIES, ACCESSORIES
} from '../../models/price-point/price-point.logic';
import { PricePointService } from '../price-point/price-point.service';
import { StatusChangeDialog } from './customer-order-status-change-dialog.component';
import { EditItemDialog } from './customer-order-edit-item-dialog.component';
import { AddItemDialog } from './customer-order-add-item-dialog.component';
import { OrderNoteDialog } from './customer-order-note-dialog.component';
import { AuthorityService } from '../../models/authority/authority.service';
import { CustomerGuidService } from './customer-guid.service';

import {
  CustomerOrderLogic,
  STATUS_SUBMITTED,
  STATUS_QUOTE_UPDATED,
  STATUS_ACCEPTED,
  STATUS_CANCELED,
  STATUS_CLOSED,
  F_SUMMARY,
  F_EMPLOYEE_SUMMARY,
  F_EMPLOYEE_EDIT,
  F_NEW_CUSTOMER_ORDER
} from './customer-order.logic';

@Component({
  selector: 'app-customer-order',
  templateUrl: './customer-order.component.html',
  styleUrls: ['./customer-order.component.css']
})

export class CustomerOrderComponent implements OnInit  {
  @Input() modelFunction = F_NEW_CUSTOMER_ORDER;
  @Input() statusKey = STATUS_SHOPPING;
  @Input() orderNumber : string;
  @Input() items : Object; // { pricePoint: itemObject }
  @Input() pricePointId : string;
  @Input() summaryTitle : string;
  @Input() payMethod : string;
  @Input() payContact : string;
  @Input() isShip : boolean;
  @Input() isExpedite : boolean;
  @Input() isInsure : boolean;
  @Input() accessories : Array<string>;

  statusDescription = 'Your order has not been submitted yet.  Add more items or submit your order using the buttons below.';
  CARRIER_NAMES = CARRIERS;
  CONDITION_NAMES =  CONDITIONS;
  PROCESSOR_NAMES = PROCESSORS
  STORAGE_NAMES = STORAGES;
  OPERATION_NAMES = OPERATIONS;
  BATTERY_NAMES = BATTERIES;
  editedItems = new Set();
  addedItems = new Set();
  removedItems = new Set();
  editProgressItems = {};
  canCancelOrder = false;
  orderNote = '';

  constructor(
    private customerOrderService : CustomerOrderService,
    private authorityFunctionService : AuthorityFunctionService,
    private pricePointService : PricePointService,
    private router: Router,
    private changeDetection : ChangeDetectorRef,
    private ngZone : NgZone,
    private dialog: MatDialog,
    private authService : AuthorityService,
    private guidService : CustomerGuidService
  ) {
    this.handleOrderStarted = this.handleOrderStarted.bind(this);
    this.handleOrderUpdated = this.handleOrderUpdated.bind(this);
    this.popupStatusChange = this.popupStatusChange.bind(this);
    this.handleItemsRemoved = this.handleItemsRemoved.bind(this);
    this.handleItemAdded = this.handleItemAdded.bind(this);
    this.handleNewSubmission = this.handleNewSubmission.bind(this);
    this.handleOrderLoaded = this.handleOrderLoaded.bind(this);
    this.handleUpdateOrderItems = this.handleUpdateOrderItems.bind(this);
    this.handleEditPricePoint = this.handleEditPricePoint.bind(this);
    this.handleUpdateComplete = this.handleUpdateComplete.bind(this);
    this.handleOrderNote = this.handleOrderNote.bind(this);
    this.handleCancelOrderAuthority = this.handleCancelOrderAuthority.bind(this);
    this.handleOrderCancelled = this.handleOrderCancelled.bind(this);
  }

  handleOrderStarted(success, data) {
    if (success) {
      this.modelFunction = F_SUMMARY;
      this.items[this.pricePointId] = CustomerOrderLogic.startCustomerOrder_item(data);
      this.orderNumber = data.orderNumber;
      this.makeSummaryTitle();
      this.ngZone.run(() => { this.changeDetection.detectChanges() });
    } // else boom
  }

  handleOrderCancelled() {
    this.ngZone.run(() => { this.router.navigate(['/eo/dashboard']); });
  }

  handleOrderUpdated(success, newStatusKey) {
    if (success) {
      this.statusKey = newStatusKey;
      this.statusDescription = CustomerOrderLogic.updateOrderStatus_statusDescription(
        this.statusKey, this.isShip, this.isExpedite
      );
      this.ngZone.run(() => { this.changeDetection.detectChanges() });
      if (STATUS_CANCELED === newStatusKey || STATUS_CLOSED === newStatusKey) {
        this.customerOrderService.inactivateOrder(this.orderNumber, this.handleOrderCancelled);
      }
      this.customerOrderService.customerOrderNotification(this.orderNumber, 'order_status_change');
      this.guidService.startNewCart();
    } // else boom
  }

  handleItemsRemoved(success, isOrderRemoved, pricePointId) {
    if (success) {
      if (isOrderRemoved) {
        this.ngZone.run(() => this.router.navigate(['/home']));
      } else {
        delete this.items[pricePointId];
        this.ngZone.run(() => { this.changeDetection.detectChanges() });
      }
    } // else waza?
  }

  handleItemAdded(success) {
    if (success) {
      return this.customerOrderService.getCustomerOrder(this.orderNumber, (order) => {
        this.modelFunction = F_SUMMARY;
        this.handleOrderLoaded(order);
      });
    } // else boomy
  }

  handleNewSubmission(success) {
    if (success) {
      this.customerOrderService.customerOrderNotification(this.orderNumber, 'order_status_change');
      return this.customerOrderService.getCustomerOrder(this.orderNumber, (order) => {
        this.modelFunction = F_SUMMARY;
        this.guidService.startNewCart();
        this.handleOrderLoaded(order);
      });
    } // else boomy
  }

  handleOrderLoaded(order) {
    this.items = CustomerOrderLogic.getCustomerOrder_items(order.items)
    this.statusKey = order.status_key;
    this.isShip = order.is_ship;
    this.isExpedite = order.is_expedite;
    this.isInsure = order.is_insure;
    this.payMethod = order.payment_method;
    this.payContact = order.payment_contact;
    this.statusDescription = CustomerOrderLogic.updateOrderStatus_statusDescription(
      this.statusKey, this.isShip, this.isExpedite
    );
    this.makeSummaryTitle();
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }

  sortedItems(items) {
    return Object.values(items).sort(CustomerOrderLogic.itemNameCompator());
  }

  statusSummary() {
    return CustomerOrderLogic.statusSummary(this.statusKey, this.isShip, this.isExpedite);
  }

  makeSummaryTitle() {
    const longTitle = this.sortedItems(this.items).map(item => item['itemName']).join(', ');
    let title = longTitle.substring(0, 100);
    if (longTitle.length > 100) title += '...';
    this.summaryTitle = title;
  }

  orderTotal() {
    return Object.values(this.items).reduce((val, item) => val + (item.quantity * item.price), 0);
  }

  pricePointForItem(item) {
    return parseInt(Object.keys(this.editProgressItems).find(k => this.editProgressItems[k] === item));
  }

  removeItem(item) {
    const pricePoint = this.pricePointForItem(item);
    delete this.editProgressItems[pricePoint];
    if (this.items[pricePoint]) this.removedItems.add(`${pricePoint}`);
    this.editedItems.delete(`${pricePoint}`);
    this.addedItems.delete(`${pricePoint}`);
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
    return false;
  }

  editItem(item) {
    this.pricePointService.getPricePoints(
      (pricePoints) => { this.handleEditPricePoint(pricePoints, item); },
      item.itemId
    );
    return false;
  }

  handleEditPricePoint(pricePoints, item) {
    const selectedPricePoint = this.pricePointForItem(item);
    const editItemDialogRef = this.dialog.open(
      EditItemDialog,
      { data: { pricePoints, selectedPricePoint, quantity: item.quantity, itemName: item.itemName }}
    );
    editItemDialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.pricePoint != selectedPricePoint) {
          this.editProgressItems[result.pricePoint] = CustomerOrderLogic.editItemDialog_pricePoint(
            item, result.pricePoint, pricePoints
          );
          delete this.editProgressItems[selectedPricePoint];
          if (!this.items[result.pricePoint]) {
            this.addedItems.add(result.pricePoint);
            this.removedItems.add(selectedPricePoint);
          }
        } else if (this.items[result.pricePoint] && (result.quantity != this.items[result.pricePoint].quantity)) {
          this.editedItems.add(result.pricePoint);
        }
        this.editProgressItems[result.pricePoint].quantity = result.quantity;
        this.ngZone.run(() => { this.changeDetection.detectChanges() });
      }
    });
    this.ngZone.run(() => { this.changeDetection.detectChanges(); });
  }

  popupStatusChange() {
    const data = CustomerOrderLogic.statusChangeDialog_data(this.canCancelOrder, this.statusKey);
    const statusDialogRef = this.dialog.open(StatusChangeDialog, { data });

    statusDialogRef.afterClosed().subscribe(resultKey => {
      if (resultKey && resultKey.length && resultKey != this.statusKey) {
        this.customerOrderService.updateOrderStatus(this.orderNumber, resultKey, this.handleOrderUpdated);
      }
    });
  }

  handleUpdateComplete(success) {
    if (success) {
      this.customerOrderService.getCustomerOrder(this.orderNumber, this.handleOrderLoaded);
      // needed? this.customerOrderService.customerOrderNotification(this.orderNumber, 'order_status_change');
    } // else boom
  }

  handleUpdateOrderItems(currentIndex, operations, hasFailure) {
    if (currentIndex < operations.length) {
      const cb = (success) => { this.handleUpdateOrderItems(currentIndex + 1, operations, hasFailure || !success); };
      CustomerOrderLogic.executeUpdateOrderItems(
        operations[currentIndex],
        cb,
        this.customerOrderService,
        this.orderNumber
      );
    } else {
      if (hasFailure) {
        this.ngZone.run(() => {
          this.router.navigate(['/eo/dashboard', { key: 'order_update', success: false }]);
        });
      }
      else {
        this.customerOrderService.updateOrderStatus(this.orderNumber, STATUS_QUOTE_UPDATED, this.handleUpdateComplete);
      }
    }
  }

  handleOrderNote(success, anOrderNote) {
    if (success && anOrderNote) {
      this.orderNote = anOrderNote;
      this.ngZone.run(() => { this.changeDetection.detectChanges(); });
    } // if not success boom
  }

  handleCancelOrderAuthority(hasAccess) {
      this.canCancelOrder = hasAccess;
  }

  actionPrimary() {
    switch(this.modelFunction) {
      case F_SUMMARY:
        switch(this.statusKey) {
          case STATUS_SHOPPING:
            this.ngZone.run(() => this.router.navigate(['/order_options']));
            break;
          case STATUS_QUOTE_UPDATED:
            this.customerOrderService.updateOrderStatus(this.orderNumber, STATUS_ACCEPTED, this.handleOrderUpdated);
            break;
        }
        break;
      case F_EMPLOYEE_SUMMARY:
        this.popupStatusChange();
        break;
      case F_EMPLOYEE_EDIT:
        this.modelFunction = F_EMPLOYEE_SUMMARY;
        this.ngZone.run(() => { this.changeDetection.detectChanges(); });
        const operations = CustomerOrderLogic.prepareUpdateOrderOperations(
          this.editProgressItems,
          this.editedItems,
          this.addedItems,
          this.removedItems
        );
        if (operations.length) {
          this.handleUpdateOrderItems(0, operations, false);
        }
        break;
    }
  }

  actionSecondary() {
    switch(this.modelFunction) {
      case F_SUMMARY:
        switch(this.statusKey) {
          case STATUS_SHOPPING:
            this.ngZone.run(() => { this.router.navigate(['/home']) });
        }
        break;
      case F_EMPLOYEE_SUMMARY:
        this.modelFunction = F_EMPLOYEE_EDIT;
        this.editProgressItems = {...this.items};
        this.editedItems.clear();
        this.addedItems.clear();
        this.removedItems.clear();
        this.ngZone.run(() => { this.changeDetection.detectChanges() });
        break;
      case F_EMPLOYEE_EDIT:
        const addItemDialogRef = this.dialog.open(AddItemDialog);
        addItemDialogRef.afterClosed().subscribe(result => {
          if (result) {
            const pricePointId = Object.keys(result)[0];
            if (!this.editProgressItems[pricePointId]) {
              this.editProgressItems = { ...this.editProgressItems, ...result }
              if (this.items[pricePointId]) { // item was deleted then re-added
                this.removedItems.delete(pricePointId);
                if (this.items[pricePointId].quantity != result[pricePointId].quantity) {
                  this.editedItems.add(pricePointId);
                }
              } else {
                this.addedItems.add(pricePointId);
              }
            } else {
              if (this.items[pricePointId]) {
                this.editedItems.add(pricePointId);
              }
              this.editProgressItems[pricePointId].quantity += result[pricePointId].quantity;
            }
            this.ngZone.run(() => { this.changeDetection.detectChanges() });
          }
        });
        this.ngZone.run(() => { this.changeDetection.detectChanges(); });
        break;
    }
  }

  actionTertiary() {
    switch(this.modelFunction) {
      case F_EMPLOYEE_SUMMARY:
        this.ngZone.run(() => { this.router.navigate(['/eo/dashboard']) });
        break;
      case F_EMPLOYEE_EDIT:
        this.modelFunction = F_EMPLOYEE_SUMMARY;
        this.ngZone.run(() => { this.changeDetection.detectChanges() });
        break;
    }
  }

  actionQuaternary() {
    switch(this.modelFunction) {
      case F_EMPLOYEE_SUMMARY:
        const noteDialogRef = this.dialog.open(OrderNoteDialog, { data: this.orderNote });
        noteDialogRef.afterClosed().subscribe(resultNote => {
          if (resultNote && resultNote.length && resultNote != this.orderNote) {
            const order = this.orderNumber;
            const cb = this.handleOrderNote;
            this.customerOrderService.updateOrderNote(
              this.orderNumber,
              resultNote,
              (success) => { if (success) this.customerOrderService.loadOrderNote(order, cb); }
            );
          }
        });
        break;
    }
  }

  removeItems(itemData) {
    const pricePointId = Object.keys(this.items).find(ppId => this.items[ppId] === itemData);
    this.customerOrderService.removeItems(this.orderNumber, pricePointId, this.handleItemsRemoved);
    return false;
  }

  accessoriesDisplay(accessories) {
    const aMap = accessories.reduce(
      (result, value) => { const count = result[value] || 0; return { ...result, [value]: count + 1 }; },
      {}
    )
    return Object.keys(aMap).map(accessoryKey => `${aMap[accessoryKey]} x ${ACCESSORIES[accessoryKey]}`).join(', ');
  }

  ngOnInit() {
    switch(this.modelFunction) {
      case F_NEW_CUSTOMER_ORDER:
        this.items = {};
        if (this.orderNumber) {
          if (this.pricePointId) {
            return this.customerOrderService.addOrderItem(
              this.orderNumber,
              parseInt(this.pricePointId),
              null,
              this.accessories?.join(','),
              this.handleItemAdded
            );
          } else {
            return this.customerOrderService.updateOrderStatusShipping(
              this.orderNumber, STATUS_SUBMITTED, this.payMethod, this.payContact, this.isShip, this.isExpedite, this.isInsure, this.handleNewSubmission
            );
          }
        }
        else return this.customerOrderService.startCustomerOrder(
          parseInt(this.pricePointId), this.accessories?.join(','), this.handleOrderStarted
        );
        break;
      case F_EMPLOYEE_SUMMARY:
        this.customerOrderService.loadOrderNote(this.orderNumber, this.handleOrderNote);
        this.authorityFunctionService.getAuthorization('cancel_customer_order', this.handleCancelOrderAuthority);
      case F_SUMMARY:
        this.customerOrderService.getCustomerOrder(this.orderNumber, this.handleOrderLoaded);
        break;
    }
  }
}
