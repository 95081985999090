import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-customer-order-review-page',
  templateUrl: './review-page.component.html',
  styleUrls: ['./summary-page.component.css', '../page.css']
})

export class OrderReviewPageComponent {
  pricePointIdentifier : string;
  existingOrderNumber : string;
  title : string = 'Your Orders';
  subtitle : string = 'Buy Back';

  constructor(
    route: ActivatedRoute
  ) {
    this.existingOrderNumber = route.snapshot.paramMap.get('order_number');
  }
}
