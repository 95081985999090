import { Component, ChangeDetectorRef, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CatalogBrandService } from '../../../models/catalog-brand/catalog-brand.service';
import { CatalogItemService } from '../../../models/catalog-item/catalog-item.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

const OP_FIELD = 'changeOperation';
const VAL_FIELD = 'changeValue';

@Component({
  selector: 'app-price-change-page',
  templateUrl: './price-change-page.component.html',
  styleUrls: ['../../page.css', './price-change-page.component.css']
})

export class PriceChangeComponent implements OnInit {
  title : string = 'Change price point(s)';
  subtitle : string = 'Buy Back';
  loading = false;
  verifying = false;
  typeKey : string;
  targetId : string;
  changeForm : FormGroup;
  targetName : string;
  categoryIdentifier: string;

  constructor(
    route : ActivatedRoute,
    private router: Router,
    private catalogBrandService : CatalogBrandService,
    private catalogItemService : CatalogItemService,
    private ngZone : NgZone,
    private changeDetection: ChangeDetectorRef
  ) {
    this.typeKey = route.snapshot.queryParamMap.get('type_key');
    this.targetId = route.snapshot.queryParamMap.get('target_id');
    this.categoryIdentifier = route.snapshot.queryParamMap.get('category_id');
    this.changeForm =  new FormGroup({
      [OP_FIELD]: new FormControl('', Validators.required),
      [VAL_FIELD]: new FormControl('', Validators.required)
    });
    this.handlePriceChanged = this.handlePriceChanged.bind(this);
    this.handleBrands = this.handleBrands.bind(this);
    this.handleItem = this.handleItem.bind(this);
  }

  handlePriceChanged(result) {
    const success = result && result.success;
    this.ngZone.run(() => {
      this.router.navigate(
        ['/eo/dashboard', { key: 'price_update', success: !!success, num_updated: result.pricesUpdated }]
      );
    });
  }

  cancelChanges() {
    this.ngZone.run(() => { this.router.navigate(['/eo/dashboard', { key: 'price_update',  cancel: true }]); });
  }

  redoChanges() {
    this.verifying = true;
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }

  verifyChange() {
    this.changeForm.controls[VAL_FIELD].markAsTouched();
    if (this.changeForm.valid) {
      this.verifying = true;
    }
    this.ngZone.run(() => { this.changeDetection.detectChanges() });
  }

  changePrices() {
    if (!this.loading) {
      this.verifying = false;
      switch (this.typeKey) {
        case 'brand':
          this.loading = true;
          this.catalogBrandService.updatePricePoints(
            this.handlePriceChanged,
            this.targetId,
            this.categoryIdentifier,
            this.changeForm.controls[OP_FIELD].value,
            this.changeForm.controls[VAL_FIELD].value
          );
          break;
        case 'item':
          this.loading = true;
          this.catalogItemService.updatePricePoints(
            this.targetId,
            this.changeForm.controls[OP_FIELD].value,
            this.changeForm.controls[VAL_FIELD].value,
            this.handlePriceChanged
          );
          break;
      }
      this.changeForm.reset();
      this.ngZone.run(() => { this.changeDetection.detectChanges() });
    }
  }

  handleBrands(brands) {
    this.targetName = brands.find(brand => this.targetId === `${brand.identifier}`).name;
  }

  handleItem(item) {
    this.targetName = item.name;
  }

  ngOnInit() {
    switch (this.typeKey) {
      case 'brand':
        this.catalogBrandService.getBrands(this.handleBrands, this.categoryIdentifier);
        break;
      case 'item':
        this.catalogItemService.getItem(this.handleItem, this.targetId);
        break;
    }
  }
}
