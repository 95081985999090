import { Injectable } from '@angular/core';
import { v4 } from 'uuid';
import { LocalStorageService } from 'ngx-webstorage';

const CART_ID_KEY = 'bbb.cartIdentifier';

@Injectable()
export class CustomerGuidService {
  constructor(private storage : LocalStorageService) {}

  getCartIdentifier() {
    let cartId = this.storage.retrieve(CART_ID_KEY);
    if (!cartId) {
      cartId = v4();
      this.storage.store(CART_ID_KEY, cartId);
    }
    return cartId;
  }

  startNewCart() {
    this.storage.store(CART_ID_KEY, null);
  }
}
