import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

const subtitles = {
  faq: 'Frequently Asked Questions'
}

@Component({
  selector: 'app-content-page',
  templateUrl: './content-page.component.html',
  styleUrls: ['./content-page.component.css', '../page.css']
})
export class ContentPageComponent {
  page : string;
  subtitle : string;

  constructor(
    private route: ActivatedRoute,
  ) {
    this.page = this.route.snapshot.paramMap.get('page_key')
    this.subtitle = subtitles[this.route.snapshot.paramMap.get('page_key')];
  }
}
