import { Component, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthorityService } from '../../../models/authority/authority.service';
import { AU_USER, AU_PASS, AU_CAPTCHA } from '../../../models/authority/authority.component';

@Component({
  selector: 'app-eo-signin-page',
  templateUrl: './signin-page.component.html',
  styleUrls: ['../../page.css', './signin-page.component.css']
})

export class EmployeeSigninPageComponent implements OnInit {
  subtitle : string = 'Employee Sign In';
  signinForm : FormGroup;
  showRecaptcha : boolean;
  isEmployee : boolean = true;
  formSubmitted : boolean = false;

  constructor(
    private router: Router,
    private authService : AuthorityService,
    private snackBar: MatSnackBar,
    private changeDetection : ChangeDetectorRef,
    private ngZone : NgZone
  ) {
    this.handleSignIn = this.handleSignIn.bind(this);
  }

  ngOnInit() {
    this.signinForm =  new FormGroup({
      [AU_USER]: new FormControl('', Validators.required),
      [AU_PASS]: new FormControl('', Validators.required)
    });
  }

  handleSignIn(isSignedIn, isMaxRetriesExceeded) {
    if (isSignedIn) {
      this.ngZone.run(() => this.router.navigate(['/eo/dashboard']));
    } else {
      this.formSubmitted = false;
      this.showRecaptcha = isMaxRetriesExceeded === true;
      if (this.showRecaptcha) {
        this.signinForm.addControl(AU_CAPTCHA, new FormControl(null, Validators.required));
      }
      this.snackBar.open('Signin failed, please try again.', 'Dismiss', { duration: 10000 });
      this.ngZone.run(() => { this.changeDetection.detectChanges() });
    }
  }

  submitForm() {
    let captchaValue = null;
    this.signinForm.controls[AU_USER].markAsTouched();
    this.signinForm.controls[AU_PASS].markAsTouched();
    if (this.showRecaptcha) {
      this.signinForm.controls[AU_CAPTCHA].markAsTouched();
      captchaValue = this.signinForm.controls[AU_CAPTCHA].value
    }
    if (this.signinForm.valid) {
      this.formSubmitted = true;
      this.authService.signInUser(
        this.signinForm.controls[AU_USER].value,
        this.signinForm.controls[AU_PASS].value,
        captchaValue,
        this.isEmployee,
        this.handleSignIn
      );
      this.signinForm.removeControl(AU_CAPTCHA);
      this.showRecaptcha = false;
      this.signinForm.reset();
    }
  }
}
