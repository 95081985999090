import { Injectable } from '@angular/core';
import rp from 'request-promise';
import { environment } from '../../../environments/environment';

const serverAdx = environment.apiHost;

const baseRoute = '/api/catalog/item';

@Injectable()
export class CatalogItemService {
  getFeaturedItems(callback, category = null, brand = null) {
    const route = category && brand ? `/featured/category/${category}/brand/${brand}` : '/featured';
    return rp(`${serverAdx}${baseRoute}${route}`).promise().bind(this)
      .then(function (responseBody) {
          const catalogItems = JSON.parse(responseBody);
          callback(catalogItems['items'], brand, category);
      }).catch(() => {});
  }

  getByBrand(callback, category, brand) {
    return rp(`${serverAdx}${baseRoute}/category/${category}/brand/${brand}`).promise().bind(this)
      .then(function (responseBody) {
          const catalogItems = JSON.parse(responseBody);
          callback(catalogItems['items'], catalogItems['brand_name']);
      }).catch(() => {});
  }

  getItem(callback, identifier) {
    return rp(`${serverAdx}${baseRoute}/${identifier}`).promise().bind(this)
      .then(function (responseBody) {
          const catalogItem = JSON.parse(responseBody);
          callback(catalogItem['item']);
      }).catch(() => {});
  }

  updatePricePoints(itemId, actionKey, actionValue, callback) {
    const options = {
      method: 'PUT',
      uri: `${serverAdx}/api/catalog/item/${itemId}/pricepoint`,
      body: { actionKey, actionValue },
      json: true,
      withCredentials: true
    };
    return rp(options).promise().bind(this)
      .then(function(responseBody) {
        callback(responseBody.result);
      })
      .catch(function () {
        // const responseBody = errorObject.response.body;
        callback(false);
      });
  }

  searchItems(callback, searchTerms) {
    const options = {
      method: 'POST',
      uri: `${serverAdx}/api/catalog/item/search`,
      body: { searchTerms },
      json: true,
      withCredentials: true
    };
    return rp(options).promise().bind(this)
      .then(function(responseBody) {
        callback(responseBody.items);
      })
      .catch(function () {
        // const responseBody = errorObject.response.body;
        callback(false);
      });
  }
}
